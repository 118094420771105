export const i18n_en_us = {
  Real_money: 'Real money',
  Play_money: 'Play money',
  in_Real_Money: 'in Real Money',
  in_Play_Money: 'in Play Money',
  Login_to_your_account: 'Login to your account',

  Proceed: 'Proceed',

  global: {
    locale: 'en_US',
    content_language: 'en',
    general_description:
      'Find the most accurate and up-to-date forecasts on Futuur.  Better predictions, driven by the wisdom of the crowd.',
  },

  error_messages: {
    feed_page_crash: 'This wasn&apos;t predicted <br /> We we&apos;re unable to load the markets, please try again',
    wager_balance_not_enough: 'Not enough funds in your wallet.',
    wager_question_closed: 'Question not available for betting.',
    wager_outcome_conflict: 'You already have a wager on another outcome of this question. Sell it and try again.',
    wager_amount_invalid: 'The amount defined is not valid. Please select a valid amount or contact us for support.',
    wager_max_shares_per_wager: 'Sorry, you have reached the max shares per wager ',
  },
  cancel_withdraw: {
    exit: 'TAKE ME BACK',
    title: 'Are you sure you want to cancel this request?',
    action: 'I WANT TO CANCEL REQUEST',
    description:
      'Your transaction will remain in your account, and will not be transferred. You can make another request at any time',
  },
  snackbar: {
    copylink: 'Copied to the Clipboard',
  },

  footer: {
    title: 'Bet on the future',
    about: 'About Us',
    signup: 'Sign up now',
    how_works: 'About Futuur',
    terms: 'Terms and Conditions',
    privacy: 'Privacy Policy',
    contest_rules: 'Competition Rules',
    explore: 'Explore',
    leaderboard: 'Top Forecasters',
    questions: 'Top Markets',
    politics: 'Politics',
    sports: 'Sports',
    finance: 'Finance',
    entertainment: 'Entertainment',
    science: 'Science',
    engage: 'Engage',
    suggest: 'Suggest a Market',
    contact: 'Contact Us',
    community: 'Community',
    language_title: 'Language',
    language: 'Português Brasileiro',
    copyright: 'Futuur© all rights reserved',
    report: 'Report a bug',
    hiring: "We're hiring",
    api_docs: 'Public API',
    help_center: 'Help Center',
    disclaimer:
      'Futuur is a prediction market providing more accurate forecasts across a range of topics, powered by the wisdom of the crowd. This service is owned and operated by Futuur BV, a Curaçao company with offices at Abraham Mendez Chumaceiro Blvd. 03, Willemstad, Curaçao.  Curaçao Gaming License #8048/JAZ2019-058.',
  },

  login_reset: {
    title: 'Login problems?',
    insert_your_email: "Insert your e-mail and we'll send you a recovery link",
    recover: 'Recover',
    invalid_email: 'Invalid e-mail',
    success: 'Password updated',
    reset_password: 'Redefinir Senha',
    success_text: 'Now login to your account and happy forecasting!',
    invalid_token_param: 'A URL acessada é inválida',
    error: 'Token is invalid. Please request password recovery again.',
  },

  signup: {
    welcome: 'First step to become a forecaster!',
    signin: 'Login',
    first_name: 'First name',
    last_name: 'Last name',
    full_name: 'Full name',
    email: 'Email',
    password: 'Password',
    welcome_to_futuur: 'Welcome to Futuur',
    pick_a_username: 'How do you want to be known?',
    username_placeholder: 'myusername',
    password_helper_1: 'more than 8 characters',
    password_helper_2: 'contain numbers and letters',
    password_helper_3: 'at least one special character',
    error_email: 'invalid address',
    error_first_name: 'Must fill your first name',
    error_last_name: 'Must fill your last name',
    error_name: 'Must fill your full name',
    error_too_short: 'must be longer than 8 characters',
    create_account: 'Create Account',
    set_pp: 'Set your profile picture, ',
    continue: 'Continue',
    username: 'Username',
    pick_username: 'Pick Username',
    already_forecaster: 'Already have an account?',
    email_placeholder: 'example@email.com',
    title: 'Futuur ~ Sign Up',
    choose_username: 'Please choose a username',
    you_got_mail: 'Verify your email to activate your account',
    setting_up_account: 'Setting up your account...',
    username_warning: 'It can’t be changed later, choose well',
    choose_profile_picture: 'Please choose a profile picture',
    choose_profile_picture_info: 'You can change at anytime in your account settings',
    account_settings: 'account settings',
    ready_to_predict: 'Ready to Predict',
    proceed: 'Proceed',
    add_picture_later: 'Add picture later?',
    skip: 'Skip for now',
    terms: 'Terms',
    privacy_policy: 'Privacy Policy',
    check_out_1: 'Check out our ',
    check_out_2: ' and ',
    enter_with_email: 'Enter with email',
    verification_info: 'For any uncertainties, consult our ',
    identity_verification_policy: 'identity verification policy',
    proceed_without_verifying: 'Proceed without verifying?',
    verify_later: 'Verify later',
    email_verified: 'E-mail Verified!',
    time_to_predict_the_future: 'Cool! Now it’s time to start predicting the future!',
    start_forecasting: 'Start Forecasting',
    login_to_your_account: 'Log in to your account',
    account_username_already_taken: 'Account username already taken',
    invalid_link: 'The link you clicked is not valid.',
    go_to_futuur: 'Go to Futuur',
  },

  signin: {
    title: 'Futuur ~ Sign In',
  },

  maintenance: {
    title: 'Futuur is currently down for maintenance',
    text: 'We expect to be back in a couple of hours. Thanks for your patience.',
  },

  tags: {
    meta_tags: {
      title: 'Prediction markets for every topic, powered by the crowd.',
      description: 'Hundreds of prediction markets, with forecasts powered by the wisdom of the crowd.',
    },
  },
  tag: {
    meta_tags: {
      title: '${this.tag} predictions, powered by the crowd',
      description: 'Explore over ${this.marketCount} ${this.tag} prediction markets on Futuur.',
    },
  },
  landing: {
    meta_tags: {
      title: '🔮 Futuur ~ Bet on the Future',
      description:
        'Explore over ${this.marketCount} crowd-powered forecasts on the leading prediction market platform, and make your own bet on the future using real or play-money.',
    },
    title_line1: 'Predict',
    title_line2: 'the Future',
    hero_text:
      'Futuur is a community-based forecasting game, and a platform for predicting the future using collective intelligence.',
    start_forecasting: 'Start Forecasting',
    view_predictions: 'View Predictions',
    fb_enter: 'Enter with Facebook',
    signup: 'Enter with Email',
    learn_more: 'Learn more',
    watch: 'Watch Video',
    how_it_works: 'How it Works',
    categories_banners: 'Hot topics 🔥',
  },

  how_it_works: {
    intro: {
      title: 'At Futuur, you can bet on <b>everything</b> <br/> & help generate better forecasts for <b>everybody</b>',
      p1: 'Futuur is a prediction market that harnesses collective wisdom to generate more precise forecasts on diverse subjects.  It offers better insights and rewards for accurate predictions.',
      p2: 'On Futuur, you can bet on the future of just about anything',
      p3: 'Bet for your rep with Play Money, or put some more skin in the game and bet with any of the Major Cryptocurrencies',
      cta: 'join now',
    },
    header: {
      title1: 'Get started forecasting',
      title2: 'in just a few steps',
    },
    step1: {
      title1: 'Sign up',
      title2: ' with email or Google',
      paragraph:
        'Registration is fast and free.  And you’ll get 10,000ø in play-money to kick off your career as a forecaster!',
    },
    step2: {
      title1: 'Add funds',
      title2: ' to bet with real money, or bet for fun with play-money',
      paragraph:
        ' Deposit with your choice of currencies (USD, EUR, BRL, and others), or with crypto. And withdraw whenever you want.',
    },
    step3: {
      title1: 'Choose from',
      title2: ' hundreds of markets to predict',
      paragraph: 'Futuur offers the most real-money markets anywhere, covering pretty much every topic imaginable!',
    },
    step4: {
      title1: 'Start betting',
      paragraph:
        "Market odds mirror the crowd's prediction of an outcome's likelihood, and can be a profit opportunity if you spot a mispricing.",
    },
    join_tips: {
      header1: 'Join a global ',
      header2: 'community of forecasters',
      step1: {
        title1: 'Follow',
        title2: ' the smart money',
        paragraph: 'Find and follow the most prescient predictors on our leadboards.',
      },
      step2: {
        title1: 'Make your voice ',
        title2: 'heard',
        paragraph: 'Leverage comments for insights and contribute to the discussion.',
      },
      step3: {
        title1: 'Suggest',
        title2: ' markets',
        paragraph: 'Make your suggestion and get a clearer view of the future by leveraging collective intelligence.',
      },
    },
    use_strategy_title: 'Use strategy to bet and win!',
    use_strategy_paragraph:
      'Prices on Futuur are determined by market activity. You can sell buy and sell positions at anytime as prices fluctuate, securing gains without waiting for the event outcome.',
    hot_topic: 'Hot topics to bet 🔥',
  },
  comments_panel: {
    you_liked_this: 'you liked this.',
    user_liked_this: ' users like this.',
    you_and_users_liked_this: 'you and one more user like this.',
    you_and_more: 'you and more ',
  },
  feed_banner: {
    Trade_the_future: 'Bet on the Future',
    Future_of_forecasting: 'The future of forecasting',
    intro_text: 'Make money with what you know and help generate better forecasts for everyone.',
    start_forecasting: 'Start forecasting',
    make_your_forecasts: 'Make your forecasts',
  },
  location_not_allowed: {
    title: 'Your location is restricted.',
    text_part1:
      "Your jurisdiction does not allow for real-money betting on Futuur.  But don't worry! You can use Ooms, our play-money currency, to make bets and prove your forecasting skills.  Just click on the account balance above to switch to Ooms.",
    text_part2: 'for more info.',
    portfolio_text_part1:
      'Portfolio is not available. To see your Ooms portfolio, click on your account balance above and select Ooms',
    terms_conditions: 'Terms and Conditions',
  },
  not_enough_funds: {
    content: "You don't have enough funds to place a bet using this currency. Please add funds to make your forecast.",
    text: 'Lorem ipsum dolor sit amet consectetur adip sit biti adip sit amet consectetur adip',
    add_funds: 'Add funds',
    use_another_currency: 'Use another currency? Change ',
    here: 'here',
  },

  infinite_grid: {
    load_more_markets: 'Load more Markets',
    load_more: 'Load more',
    retry: 'Retry',
  },

  login: {
    presentation:
      ' is a community-based forecasting game and platform for predicting the future using collective intelligence.',
    login: 'Log In',
    signup: 'Sign Up',
    facebook: 'Enter with facebook',
    or_separator: 'or',
    email_label: 'E-mail',
    login_to_futuur: 'Welcome back to Futuur!',
    create_your_account: 'Create your account',
    email_invalid: 'Invalid address',
    google_login: 'Enter with Google',
    password_label: 'Password',
    password_invalid: 'Must be longer than 8 characters and contain numbers and letters',
    login_button: 'Login',
    signup_with_email: 'Sign up with email',
    forgot_password: 'Forgot your password?',
    terms_text1: 'By creating your account, you agree with our ',
    terms_text2: ' and ',
    terms: 'Terms of Use',
    dont_have_account: 'Dont have an account?',
    register: 'Register',
    already_forecaster: 'Already a forecaster?',
    privacy: 'Privacy Policy',
    wrong_password: `E-mail and password do not match`,
    invalid_2fa_token: `wrong 2fa code, please make sure you grab the right one`,
    server_failure: 'Server communication failure. Please contact us if it persists.',
    google_error: 'An error occurred while trying to proceed with Google authentication',
    google_error_server: 'An error occurred in our servers while trying to authenticate your Google user on Futuur',
    two_factor_auth: {
      title: 'Authentication code',
      paragraph: 'You can find your code in your authenticator app',
      have_problem: 'Having trouble? Contact our ',
      support: 'Support',
    },
  },
  page_title: {
    markets: 'Markets',
    explore: 'Explore',
    account: 'Account',
    activity: 'Activity',
    leaderboard: 'Leaderboard',
    followers: 'Followers',
    following: 'Following',
    public_profile: 'Profile',
    prediction_list: 'Predictions',

    question_suggestions: 'Question suggestions',
    question_details: 'Question details',
    category: 'Category',
  },
  navbar: {
    markets: 'Markets',
    portfolio: 'Portfolio',
    settings: 'Settings',
    notification: 'Notifications',
    leaderboard: 'Leaderboard',
    search: 'Search',
    wallet: 'Wallet',
    search_input: 'Search for predictions',
    mobile_login: 'Login',
    desktop_login: 'Login',
    signup: 'Sign Up',
    enter: 'Enter',
    fb_login: 'Enter',
    add_funds: 'Add Funds',
    deposit: 'Deposit',
  },
  navbar_menu: {
    settings: 'Account Settings',
    my_profile: 'My Profile',
  },
  bitcoin_tooltip: {
    real_money: {
      title: 'Bet with real money!',
      text1: 'Put some skin in the game by betting with real money.',
    },
    play_money: {
      title: 'Real-Money Markets',
      text1: 'We do not currently offer real-money betting in your jurisdiction.',
      text2:
        'However, you can still view the real-money probabilities (which are not necessarily the same as play-m oney markets) by clicking here.',
    },
    add_funds: 'Add Funds',
    deposit: 'Deposit Now',
    dismiss: 'Dismiss',
  },
  bitcoin_banner: {
    call_to_action: 'Take your predictions to the next level!',
    deposit: 'Deposit with Crypto',
    add_funds: 'Add funds',
    buy_bitcoin: 'Buy Crypto',
  },
  active_markets: {
    title_real_money: 'Real Money Markets',
    title_play_money: 'Play Money Markets',
  },
  go_to_web_version: {
    title: 'Take your predictions to next level!',
    p: 'Deposit with the cryptocurrency of your choice to bet on real-money markets.',
    cta: 'Go to web version',
  },
  faqs: {
    title: 'FAQ',
    link_to_external: "Go to Futuur's documentation",
    what_is_futuur: {
      title: 'What is Futuur?',
      description:
        'Futuur is a prediction market: a crowd-powered platform for generating more accurate predictions about future events. Forecasters make predictions by betting on them using their choice of play money, or crypto. Our mission is to aggregate the wisdom of the crowd to generate better predictions in a chaotic world. You can find out more about Futuur on our <a class="link" href="https://futuur.com/i/how-it-works/">About page</a>.',
    },
    how_are_priced_determined: {
      title: 'How are prices determined?',
      description:
        'Futuur uses an Automated Market Maker (AMM), an algorithm that allows us to provide a buy or sell price for any outcome at any time. This is in contrast with a traditional “order book” approach used, for instance, in a stock market or financial exchange, which requires that both sides of a trade are present at the same time in order for a trade to occur. The AMM approach has the advantage of instant, always-on liquidity for all of our markets, while at the same time maintaining the benefits of dynamic, exchange-based pricing.',
    },
    do_you_have_api: {
      title: 'Do you have a public API?',
      description:
        'Yes! You can view our <a class="link" href="https://api.futuur.com/docs/">API documentation</a> To generate a private key and begin using the API, please go to your Settings page.If you have questions, suggestions, or any other feedback regarding the API, please contact <span class="link">support@futuur.com</span>',
    },
    suggest_a_market: {
      title: 'I have an idea for a market. Where can I suggest it?',
      description:
        'We are always keen to add new and interesting markets to Futuur. If you have a suggestion for a new market, please let us know here: <a class="link" href="https://futuur.com/i/feedback/suggestion" > suggest a market.<a/>',
    },
  },
  settings: {
    title: 'Settings',
    feedback: 'Feedback',
    contact: 'Contact Us',
    suggest_question: 'Suggest a Market',
    report_bug: 'Report a Bug',
    report_bug_continuation: 'or give a general feedback',
    other_feedback: 'Send Other Feedback',
    rate_app_store: 'Rate in App Store',
    rate_play_store: 'Rate in Play Store',
    about: 'About',
    how_works: 'About Futuur',
    preferences: 'Preferences',
    language: 'Language',
    language_en: 'EN',
    language_pt: 'PT',
    account: 'Account',
    logout: 'Logout',
    notifications: 'Notifications',
    notification_live_events: 'Live Events',
    notification_question_resolution: 'Market resolution',
    notification_question_resolution_small: "on a market you've bet on",
    notification_new_followers: 'New Followers',
    notification_comments: 'Comments',
    notification_comments_small: 'on topics you are following',
    contracts: 'Contracts',
    personal_info: 'Personal Info',
    login_security: 'Login & Security',
    payments_payouts: 'Payments & Payouts',
    legal: 'Legal',
    terms_of_use: 'Terms of Use',
    privacy_policy: 'Privacy Policy',
    contest_rules: 'Competition Rules',
    go_to_profile: 'Go to profile',
    edit_profile: 'Edit my profile',
    update_password: 'Update password',
    change_email: 'Change e-mail',
    update_email: 'Update E-mail',
    you_have_an_inbox: 'You have an inbox!',
    invalid_email: 'invalid e-mail address',
    your_current_email_is: 'Your current e-mail is',
    send_confirmation: 'Send Confirmation Link',
    confirm_email_to_proceed: 'Confirm your e-mail to proceed',
    use_an_email_address_with_access: "Use an email address that you'll always have access to",
    payment_methods: 'Payment Methods',
    general_settings: 'General Settings',
    identity_verification: 'Identity Verification',
    faq: 'F.A.Q.',
    faq_continuation: 'Knowledge Base',
    update_personal_info: 'Update Personal Info',
    public_api_section: {
      title: 'Public API',
      key: 'API Key',
      beta_desclaimer:
        'Our Public API allows you to harness the power of the Futuur platform within your own applications, products, or services.',
      link_for_documentation: 'Documentation',
      pending_info: 'We’re validating your request and soon will return via email.',
      request_access: 'Enable API access',
      generate_keys: 'Generate keys',
      public: 'Public',
      private: 'Private',
      delete_keys: 'Delete keys',
      generate_new_keys: 'Generate new keys',
      request_sent_title: 'Request to access Public API done',
      modal_keys_title: 'Public/Private key pair',
      request_sent_description:
        'Congrats, you are now part of the beta API program, and can generate an access key.  Please note that the API program is in beta, and currently provides access to play-money markets only.',
      for_security_reasons:
        ' For security reasons, you can only see your private key once. DO NOT SHARE this key with anyone. If you ever lose your private key, you must generate another public/private key pair.',
      enable_to: 'Enabled to',
      must_verify:
        'The Futuur API is currently in beta, and is available in play-money only for now.  The real-money version will be available soon.',
      if_you_lose: 'If you ever lose your private key, you must generate another public/private key pair.',
    },
    two_factor: {
      on: 'On',
      off: 'Off',
      pending: 'Pending',
      info: 'The two-factor authentication protects your account by requiring a code at login.',
      enable: 'Enable',
      disable: 'Disable',
    },
  },
  two_factor_auth_validation: {
    complete: {
      title: 'Two factor added',
      paragraph: 'Two-factor authentication protects your account by requiring a login code when you log in.',
    },
    back: 'Back',
    paragraph: 'To activate your two-factor authentication, you need to:',
    step1: 'To active, scan the QR code using Auth apps such:',
    step2: 'After scanning the QR, enter the generated token',
    enable: 'Enable 2FA',
    cant_scan_code: "Can't scan? Enter the code below instead",
  },
  update_request: {
    need_update: 'Update Needed',
    update: 'Update',
    title: 'Update Your App to the newest version',
    description:
      'Oops! looks like you have an older version of Futuur. In order to keep safely making predictions, please update.',
  },
  activity: {
    tabs: {
      user: 'Your activity',
      following: 'Forecasters you follow',
    },
    empty_list: 'No notifications yet',
    live_now: 'is now live',
    you_got_it_right: 'You got it right!',
    bet_on: 'Bet on',
    resolved_on: 'Resolved on',
    incorrect: 'Incorrect',
    correct_outcome_was: 'Correct outcome was',
    withdraw_success: 'Withdrawal Completed',
    withdraw_request: 'Withdrawal Request Received',
    follow: 'Follow',
    following: 'Following',
    view_profile: 'View Profile',
    canceled: 'has been canceled',
    deposit_completed: 'Deposit Completed',
    deposit_pending: 'Pending Deposit',
    welcome_to_futuur: 'Welcome to Futuur',
    notification_amount_earned:
      'Thanks for registering as a forecaster on Futuur!  In order to access all the features on our platform, please activate your account by clicking on the button on an email we’ve sent.',
    resend: 'Re-send',
    change_email: 'Change E-mail',
  },
  filters: {
    people_i_follow: 'people i follow',
    hide_my_bets: 'Hide my bets',
    default_category: 'All Categories',
    categories: 'Categories',
  },
  markets: {
    all_markets: 'All',
    no_markets: 'No Markets found',
    no_markets_clear_filters: 'No markets found, please change applied filters.',
    title_category_prefix: '',
    title_category_suffix: ' Forecasts and Predictions | Futuur',

    title_meta: 'Forecasts and Predictions | Futuur',
    make_your_forecasts: 'Make your forecasts',
    live_markets: 'Live soon',
    resolved_only: 'Resolved Only',
    resolved_and_sold: 'Resolved And Sold',
    sort_by: 'Order By',
    most_recent: 'Recently created',
    bet_end_date: 'Closing soon',
    number_of_bets: 'Number of Bets',
    volume: 'Volume',
    relevance: 'Relevance',
    default: 'Default',
    link_copied: "Market's link copied !",
  },
  search: {
    input: 'Search for markets, categories, forecasters or tags',
    tabs: {
      all: 'All markets',
      categories: 'Categories',
      users: 'Forecasters',
      questions: 'Markets',
      tags: 'Tags',
    },
    result: {
      question: {
        title: 'Markets',
        cta: 'view all markets',
        total: 'markets',
      },
      category: {
        title: 'Categories',
        cta: 'view all categories',
        total: 'categories',
      },
      user: {
        title: 'Forecasters',
        cta: 'view all forecasters',
        total: 'forecasters',
      },
      tag: {
        title: 'Tags',
        cta: 'view all tags',
        total: 'tags',
      },
    },
    empty: {
      common: "Not finding what you're looking for ?",
      suggestion: 'Search for <a href="#">${this.query}</a> instead?',
      suggestion_cta: 'suggest a question',
    },
  },
  question_card: {
    date: {
      live: 'live now',
      waiting_result: 'Pending Resolution',
      resolved: 'resolved',
      market_resolved: 'Market resolved',
      cancelled: 'cancelled',
    },
    stats_in_play_money: 'Play Money Stats',
    stats_in_real_money: 'Real Money Stats',
    bet_on: 'Bet on',
    related_market: 'Related Markets',
    real_brier_score: 'Real Money brier score',
    play_brier_score: 'Play money brier score',
    live_in: 'Live in',
    waiting_result: 'Pending Resolution',
    finished: 'Finished',
    live_now: 'Live Now',
    brier_score_tooltip:
      'The Brier Score is a strictly proper score function or strictly proper scoring rule that measures the accuracy of probabilistic predictions. ',
    unavailable_currency:
      "This market is not available in real money. If you'd like to bet in Ooms, click on your account balance to switch.",
    tooltip_volume_PM:
      'Volume is the amount traded in a market, plus its initial liquidity.  Your are viewing play-money.  To see volume in real-money, please select a real-money option by clicking on your account balance above, in the header.',
    tooltip_volume_RM:
      'Volume is the amount traded in a market, plus its initial liquidity.  It is shown in USD equivalent.',
    forecaster: 'Forecasters',
    volume_explained: 'Amount traded in a market, plus its initial liquity.',
    tooltip_playmoney:
      'You are viewing play-money markets. To view real-money markets, please click on your account balance above and select real-money.',
    tooltip_realmoney:
      'You are viewing real-money markets. To view play-money markets, please click on your account balance above and select play-money.',
    bets_on_the_market: 'Your bets in this market',
    start_date_hour_format: 'in ${this.hours} hour${this.prularHours} and ${this.minutes} minute${this.prularMinutes}',
    start_date_hour_format_minutesOnly: 'in ${this.minutes} minute${this.prular}',
    sufix_start_date_date: 'on ',
    view_bets: 'View Bets',
    positions_in: 'sold positions ',
    currencies: 'currencies',
    bet_history: 'Bet History',
    comments: 'Comments',
    outcomes: 'Outcomes',
    ends_in: 'Ends ',
    closed: 'Closed',
  },
  question_summary: {
    market_rules: 'Market Rules',
    about: 'About this question',
    end_date: 'Bet end date',
    event_start_date: 'Event start date',
    at: 'at',
    activities: 'Activities',
    activity: 'Activity',
    transactions: ' Transactions',
    forecasters: ' forecasters',
    transaction_count: {
      transactions: '${this.transactions} transactions',
      forecasters: ' from ${this.forecasters} forecasters',
      transaction: '1 transaction',
      forecaster: ' from 1 forecaster',
    },
    in_play_money: 'in Play Money',
    in_real_money: 'in Real Money',
    see_more: 'See more',
    see_less: 'See less',
  },
  bet_status: {
    transactions: 'Transactions',
    transaction: 'Transaction',
    bet_info: 'Bet Info',
    sell_now: 'Sell',
    buy_more: 'Buy More',
    you_sold: 'You sold your position',
    disabled: 'was disabled',
    shares: 'shares',
    share: 'share',
    open: {
      bet: 'Your bet',
      win: 'If you win',
      sell: 'If you sell now',
    },
    closed: {
      bet: 'You bet on',
      won: 'You Won!',
      lost: 'You Lost',
    },
    sell: 'Sell',
    go_to_market:'Go to market',
    of:'of',
    at:'at'
  },
  bet: {
    active_purchases_amount: 'Your bet',
    avg_price: 'Av. Price',
    amount_on_win: 'If you win',
    sell_for: 'Sell for',
    pending_result: 'Pending',
    pending_info: 'The betting period has ended, and the results will be verified.',
    bet: 'Bet',
    sold: 'Sold',
    last_sell_amount: 'Sold for',
    profit: 'Profit',
    won_amount: 'Won',
    you_lost: 'Lost',
    question: 'Market',
    follow: 'You are now following ',
    unfollow: 'Unfollowed ',
    reviewing: 'Reviewing',
    paused_info: 'We are analyzing this market. Users with active bets will be notified in case of closure.',
    last_update: 'Last transaction',
    bet_end_date: 'Bet end date',
  },
  sell_bet: {
    generic_error: 'Error while loading bet. Please try again.',
    not_auth: 'User not authenticated. Please login.',
    bet_sold: 'You just sold your position for ${this.amount} ${this.currency}',
  },

  bet_sell_panel: {
    confirm: 'Confirm',
    selling: 'Selling',
    selling_for: 'Selling for',
    loss: 'Loss',
    profit: 'Profit',
    confirm_new_prices: 'Confirm new prices',
    prices_changed: 'Prices changed, please confirm',
  },

  transaction_card: {
    won: 'Won',
    sold: 'Sold',
    lost: 'Lost',
    open: 'Bet',
    canceled: 'Canceled',
    reversed: 'Reversed',
    sold_for: 'Sold for',
    bet_amount: 'Bet Amount',
    bet_on: 'Bet on',
    date_time: 'Date',
    user: 'User',
    currency: 'Currency',
    action_outcome: 'Action',
    amount: 'Amount',
    anonymous: 'Anonymous',
    withdrawal_request: 'withdrawal',
    credit: 'Credit',
    deposit: 'Deposit',
    wager_cancellation: 'Market Cancelled',
    wager_reversion: 'Resolution Review',
    initial_balance: 'Initial Balance',
    other: 'Other',
    completed: 'Completed',
    processing: 'Processing...',
    pending_confirmation: 'Pending email confirmation',
    resend_email: 'Resend email',
    or: 'Or',
    cancelWithdraw: 'Cancel this withdraw',
  },
  bet_card: {
    bet: 'Bet',
    on: 'on',
    sold: 'Sold',
    position: 'position',
    return: 'return',
    profit: 'Profit',
    loss: 'Loss',
    bought: 'bought',
    sold_bet: 'sold',
    won: 'won',
    lost: 'lost',
  },
  question_details: {
    latest_bets: 'Latest Bets',
    price_history: 'Analysing',
    price_chart_times: ['1D', '1W', '1M', '3M', '1Y'],
    filter_following: 'Filter following',
    user_and_outcome: 'User and outcome',
    amount_bet: 'Shares',
    price: 'Price',
    predictions_cta: 'View all predictions',
    meta_tags: {
      title_prefix: '',
      title_suffix: ' Predictions and Bets',
      outcome_chance: ' &ldquo;${this.outcome}&rdquo; has a ${this.chance}% chance of occurring',
      description:
        'Explore the latest ${this.subcategory} predictions powered by the crowd, and make your own bet on the future.',
    },
    sidebar_title: 'Predict',
    sidebar_subtitle: 'the Future',
    sidebar_about:
      'Futuur is a marketplace for predictions about the future. Sports, politics, entertainment, ' +
      'finance, everything! Show your knowledge and try to to be the best forecaster in the world!',
    sidebar_fb: 'continue with facebook',
    sidebar_video: 'watch video',
    sidebar_hiw: 'how it works',
  },

  place_bet: {
    your_bet: 'Your bet',
    average_price: 'Av. price / share',
    total_amout: 'Amount',
    bet: 'Bet',
    if_you_win: 'If you win',
    place_bet: 'Place bet',
    select_bet: 'Select an outcome',
    share: 'Share',
    invalid_number: 'Invalid amount',
    insufficient_funds: 'Not enough funds',
    amount_beyond_max: 'Maximum bet is ',
    amount_below_min: 'Minimum bet is ',
    outcome_not_selected: 'Please select an outcome',
    add_funds: 'Add funds',
    outcome: 'Outcome',
    reset: 'Reset',
    fix: 'Fix',
    loading: 'Loading...',
    confirm_new_prices: 'Confirm price change',
    prices_have_changed: 'Prices have changed, please confirm',
    wager_balance_not_enough: 'Your balance is not enough',
    wager_amount_invalid: 'Oops! Outdated values. Please refresh page',
    wager_max_shares_per_wager: 'Sorry, you have reached the max shares per wager ',
    add_real_money: 'Add real money',
    btc_equivalent_popup:
      'You are betting using ${this.coin}. USD equivalent amounts are shown for your convenience. Please note that the "if you win" amount is guaranteed in the currency used to bet, and may have a different USD equivalent value due to changes in exchange rate.',
    login_required: 'Please login or register to make a bet',
    you_placed_a_bet: 'You have <b>${this.count} open bets</b> in this market, would you like to make another?',
    bet_again: 'Bet again',
    resolved_market: 'This market is resolved. it is impossible to place bets on this market for now.',
    resolution: 'Resolution',
    resolved: 'Market Resolved',
    canceled: 'Market Cancelled',
    reason: 'Reason',
    cancelation_date: 'Cancelled on',
    this_market_is: 'This market is ',
    you_will_recieve_notification:
      'If you have open bets on it, you will receive a notification once it has been resolved.',
    errors: {
      amount_invalid: 'The amount you provided is not valid. Please check and try again.',
      balance_not_enough:
        'Your account balance is not sufficient to complete this transaction. Please add funds or adjust your transaction amount.',
      user_email_not_confirmed:
        'Your email address has not been confirmed yet. Please check your email and follow the instructions to confirm your email address.',
      question_closed: 'The market you are trying to access is closed. Please try another market.',
      max_shares_per_wager:
        'You have exceeded the maximum number of shares allowed for this wager. Please adjust your share count and try again.',
      wager_action_duplicated:
        'Your wager has just been processed. Please wait for 5 seconds before making another bet.',
      balance_not_enough_with_opened_withdrawals:
        'Your account balance is not sufficient to complete this transaction because there is a pending withdrawal request. Please cancel or complete the withdrawal request before trying again.',
      bets_blocked:
        'You are currently block for making new bets. Please finish your Identity Verification on your Account Settings.',
      invalid_hmac_key: 'The security key provided is invalid. Please check and try again.',
      real_money_bet_not_allowed:
        'Real money bets are not allowed. Please choose another type of bet or contact customer support for assistance.',
    },
    bet_made: 'You just made a bet of',
    bet_sell: 'You just sold your bet for',
    bought: 'Bet placed!',
    sold: 'Bet Sold!',
    view_market_details: 'View market details',
    pause_title: 'Market under evalutation',
    pause_description:
      'This market is being <b>assessed by our team.</b></br> If you have placed a bet, you will be notified in case if the market be cancelled.',
  },

  place_bet_success: {
    success: 'Success!',
    bet_on: 'Bet on',
    market: 'Market',
    your_bet: 'Your bet',
    average_price: 'Average price',
    if_you_win: 'If you win',
    share_your_bet: 'Share your bet',
  },

  question_bet: {
    place_bet: 'Place Bet',
    shares: 'Shares',
    price: 'Price',
    fb_enter: 'continue with facebook',
    email_enter: 'Sign up with email',
    average_price: 'Avg. share price',
    if_you_win: 'If you win',
    your_bet: 'Your bet',
    not_enough_funds: 'Not enough funds',
    add_funds: 'Add funds',
    nowWorth: 'Now worth',
    returnProfit: 'Profit / Loss',
    confirm_prices: 'Confirm new prices',
    prices_have_changed: 'Prices changed, please confirm',
  },
  public_make_bet: {
    text1: 'In order to place bets, please register',
    fb_enter: 'continue with facebook',
    email_enter: 'Sign up with email',
    login_enter: 'Already a forecaster?',
    login_enter_cta: 'Sign in',
  },
  public_follow: {
    text1: 'To follow',
    text2: '— and all other forecasters on Futuur —',
    text3: 'you have to be one of our registered forecasters.',
    fb_enter: 'continue with facebook',
    email_enter: 'Sign up with email',
    login_enter: 'Already a forecaster? ',
    login_enter_cta: 'Sign in',
  },
  question_item: {
    wager: {
      your_bet: 'Your bet',
      if_sell: 'If you sell now',
      sell: 'Sell',
      if_win: 'If your outcome occurs',
      math_details: 'Math Details',
      correct_prediction: 'You hit it right!',
      wrong_prediction: 'You hit it wrong!',
    },
  },
  prediction_card: {
    cta: 'share this prediction',
    paid: 'Price paid',
    total: 'Total bet',
    earnings: 'Earnings',
    losses: 'Loss',
    question_link: 'view question details',
    resolved_date: 'resolved on ',
    cancelled_date: 'cancelled on ',
    correctly_predicted: 'correctly predicted',
    incorrectly_predicted: 'incorrectly predicted',
  },
  math_details: {
    bet: {
      title: 'Your bet',
      card: 'You spent on',
      description:
        'You bought a total of <em>${this.shares}</em> shares from <em>${this.outcome}</em>, ' +
        'spending <em>${this.value}ø</em>.',
    },
    sell: {
      title: 'If you sell now',
      card: "You'll receive",
      current_price: 'Current price',
      tax: 'Tax*',
      receive: "You'll receive",
      profit: 'Profit',
      description:
        '<em>Tax</em>. A tax is applied to the final value of your purchase. This tax varies ' +
        'proportionally of how much your bet impacts the outcomes prices.',
    },
    win: {
      title: 'If your outcome wins',
      title_won: 'Your prediction was right',
      title_lost: 'If your prediction were right',
      card: "You'll receive",
      card_won: 'You received',
      card_lost: 'You would receive',
      receive: "You'll receive",
      receive_won: 'You received',
      receive_lost: 'You would receive',
      spent: 'Ooms spent',
      profit: 'Profit',
      description1:
        '<em>Your Shares</em>. If your prediction is correct, the ${this.shares} shares you purchased ' +
        'will be converted to ${this.amount_on_win}ø. The amount of Ooms you receive is always equal to the amount of ' +
        'shares you had purchased rounded down.',
      description2:
        '<em>Ooms spent</em>. This is the amount of Ooms you originally spent in your purchase. ' +
        'This information can help you calculate your profit.',
    },
    button: 'Ok, Got it',
  },
  prediction_list: {
    prediction: 'predictions',
    share: 'shares',
    price: 'price',
    badge: 'sold',
    filter_all: 'all players',
    filter_following: 'forecasters you follow',
  },
  portfolio_wallet_entry: {
    active_bets: 'Active bets',
    value_active_bets: 'Value of Active Bets',
    value_invested: 'Value invested',
    current_profit: 'Current Profit',
    return: 'Return',
  },
  portfolio: {
    header: 'Portfolio',
    add_funds: 'Add Funds',
    active_bets_in: 'Active bets in ',
    past_bets_in: 'Resolved and sold bets in ',
    real_money: 'Real Money',
    play_money: 'Play Money',
    view_wallet: 'View your wallet',
    value_of_active_bets: 'Value of Active Bets',
    add_funds_real_money: 'Add funds to place bets in real-money',
    add_funds_or_switch: 'or switch to Ooms to see your bets in play-money',
    view_markets: 'View markets',
    no_bets_in_real_money: 'You have no bets',
    no_bets_paragraph: 'Check our markets and find the most profitable for you',
    select_currency: 'Select Currency',
    tabs: {
      active: 'Open',
      closed: 'Closed',
    },
    summary: {
      value: 'Value of active bets',
      size: 'Active bets',
    },
    filter: {
      title: 'Bets in',
      sort: 'Sort & Filter',
      active: 'Active bets',
      past: 'Past bets',
    },
    card: {
      active_bets_in: 'Active Bets in',
      active_bet_in: 'Active Bet in',
      past: 'Past Bets',
      last30: 'Last 30 Days',
      active: 'Active Bets',
      resolved: 'Resolved or Sold',
      rate: 'Win Rate',
      current_profit: 'Profit',
      return: 'Return',
      value_invested: 'Value Invested',
      value_active: 'Value of Active Bets',
      add_funds: 'Add funds',
      wallet_BTC: 'Bitcoins Wallet',
      wallet_OOM: 'Ooms Wallet',
    },
    all_currencies: 'All Currencies',
    check_it_out: {
      title: 'We present you our new and improved portfolio 🔮',
      paragraph: 'Now you have <b class="font-bold">new filters</b> and <b class="font-bold">new table view.</b>',
      button: 'Check it out',
    },
    last_30_days: 'Last 30d',
    most_bet_category: 'Most bet-on category',
    you_have_no_bets:"You have no Open bets",
    bet_on_hot_topic:"How about bet on our Hot Topics? 🔥",
    life_time:"Life time",
    win_rate:"Win rate",
    bet_placed:"Bets placed",
    ranking:"Ranking",
    search_placeholder:"Search in portfolio"
  },
  portfolio_summary: {
    active_bets_singular: 'Active Bet',
    active_bets_plural: 'Active Bets',
    in: 'in',
    play_money: 'Play Money',
    real_money: 'Real Money',
  },
  profile: {
    follow_action: 'Follow',
    following_action: 'Following',
    invite_friends: 'Invite Friends',
    net_worth: 'Net Worth',
    number_of_bets: 'Bets in ${this.currency}',
    win_rate: 'Win Rate',
    total_wagers: 'Total Wagers',
    followers: 'Followers',
    following: 'Following',
    value_active_bets: 'Value of active bets',
    sell_all: 'Sell all active wagers',
    no_bets: 'No bets',
    bet: 'bet',
    bets: 'bets',
    bets_data_title: '${this.currency} Bet Data',
    sell_all_question: 'Sell all wagers?',
    account_settings: 'Account settings',
    no_users: 'No users found',
    restricted_panel_title: 'Bet History for Real Money wagers is not available',
    restricted_panel_text:
      "To view this user's Bet History in Play Money, click on your Account Balance and select Ooms.",
    meta_tags: {
      public_title: ' on Futuur',
      description_outcome:
        "See ${this.username} latest predictions on Futuur.  ${this.username} bet on &ldquo;${this.outcome}&rdquo; on the question &ldquo;${this.question}&rdquo;.  Check out ${this.username}'s other predictions and make your own for free on Futuur.  Better predictions using the wisdom of the crowd.",
      description_no_outcome:
        'See ${this.username} latest predictions on Futuur. Bet on the future of everything, from sports to politics and beyond.',
    },
    open_positions: 'Open positions',
    life_time_profit_loss: 'Lifetime profit/loss',
    market_traded: 'Market traded',
    edit_profile: 'Edit profile',
  },
  email_confirmation_required: {
    resend_email: 'Send Verification E-mail',
    change_email: 'Change E-mail',
    send_again: 'Send Again',
    you_need_verify_email: 'Please verify your email in order to place bets',
    confirmation_required_text: `For security reasons we need you to verify your email. We will also use this email address to send updates
    about your positions. You can adjust your notifications on your preferences.`,
    make_sure_to_check: 'Make sure to check junk or spam folder.',
    sender_is: 'Sender is info@futuur.com',
    email_verified: 'Email Verified!',
    email_verified_from_another_account_msg:
      "Please log in from the verified email account to use all Futuur's functionalities.",
    invalid_token: 'Invalid activation URL',
    time_to_predict_future: `Now it's time to predict the future!`,
    start_forecasting: 'Start Forecasting',
    back_to_login: 'Go Back to Login',
    modal: {
      title: 'Check your email inbox',
      description: 'Verify you email to set up your account and start betting. Check your spam inbox',
      button: 'Resend email',
      sent_title: 'Email sent!',
      sent_description: "Check your inbox, if you didn't receive, please contact our support team",
    },
  },
  notifications: {
    market_is: 'Market is',
    cancelled: 'Cancelled',
    live: 'Live',
    you: 'You ',
    the_bot_on: 'the bet on ',
    for: 'for',
    commented: 'commented',
  },
  email_sent: {
    you_got_mail: "You've got mail",
    please_check_your_inbox: 'Please check your inbox and verify your account in order to place bets.',
  },
  leaderboard: {
    meta_tags: {
      title: 'Top Forecasters on Futuur',
      description: 'Check out the best Forecasters on Futuur.  Better predictions, driven by the wisdom of the crowd.',
    },
    this_week: 'This Week',
    this_month: 'This Month',
    last_month: 'Last 30 days',
    last_day: 'Last 24 hours',
    last_7days: 'Last 7 days',
    all_time: 'All Time',
    all_categories: 'All Categories',
    user: 'Forecaster',
    total_wagers: 'Total wagers',
    earnings: 'Earnings',
    net_worth: 'Net Worth',
    leaderboard: 'Leaderboard',
    description: 'Our ranking consider the profit and loss from',
    tooltip:
      'Our ranking is based on realized profit from resolved markets and sold positions summed to the active ' +
      'bets current worth.',
    in: 'in',
    all: 'All Markets',
    hot_market: 'Hot markets',
  },
  user_ranking: {
    forecater_rank: 'Forecaster rank',
    life_time_profit: 'Profit',
    go_to_profile: 'Go to my profile',
  },
  contracts: {
    terms_title: 'Terms of Use',
    privacy_title: 'Privacy Policy',
    contest_rules_title: 'Competitions Rules',
  },
  feedback: {
    header: 'Contact Us',
    subheader: "We're looking forward to hearing from you!",
    labels: {
      type: 'Kind of contact',
      contact: 'Contact',
      subject: 'Subject',
      message: 'Message',
      text: 'Feedback',
      email: 'e-mail',
      description: 'Bug description',
      title: 'Your market title',
      category: 'What is this market related to?',
      notes: 'Notes',
      attachment: 'Attach screenshot',
    },
    type: {
      general: 'General Feedback',
      bug: 'Bug Report',
      suggestion: 'Suggest a Market',
      message: 'Message us',
    },
    submit: 'Send',
    error: {
      email: 'This is not a valid email',
    },
  },
  app_rate: {
    title: 'Show us your love!',
    text: 'Enjoying Futuur? If yes, we kindly ask you to take a moment to rate 5 star in the App Store! ',
    confirm: 'yes!',
    cancel: 'not this time',
  },
  bet_entry: {
    lost: 'Lost',
    won: 'Won',
    bet: 'Bet',
    sale: 'Sale',
    disabled: 'Disabled',
    you_earned: 'You earned',
    profit: 'with a profit of',
    you_lost: 'You lost',
    shares: 'shares at an average price of',
    total: 'per share for a total of',
    you_paid: 'You paid',
    comission: 'as comission',
    resolution: 'The correct outcome was:',
    now_worth: 'Now worth',
    earned: 'Earned',
    paid: 'and paid',
    tooltip_text: 'Futuur charges a 10% commission on profits. For more on about how we charge, please see our FAQs.',
    tooltip_label1: 'Pre-commission profit',
    tooltip_label2: 'Commission rate',
    tooltip_label3: 'Commission',
  },
  bet_info: {
    warning_line1: 'Futuur charges a 10% commission fee over your earnings.',
    warning_line2: 'For more information check the how we charge section.',
    otherBets: 'Your other bets on this market',
    aggregate: 'Aggregate results',
    past: 'Past positions',
    last: 'bet status',
    open: 'bet status',
  },
  bet_history: {
    all: 'All Bets',
    past: 'Past Bets',
    active: 'Active Bets',
    view_all: 'View All',
    following: 'View Forecasters You Follow',
    note: 'Note: ',
    tooltip_text: `for security reasons you can only see other user's Ooms data. To place bets using Ooms change your active
    currency.`,
    all_types: 'All',
    play_money: 'Play money',
    real_money: 'Real money',
    dismiss: 'Dismiss',
    only_followers: 'Only people I follow',
    my_bets: 'My bets',
    no_bet_found: 'No bet found',
    be_the_first: 'Be the first forecaster for this market',
  },
  cash_in: {
    kyc: 'Account Verification',
    kyc_title: 'Please **verify your ID** to unlock deposits',
    currencyList_subtitle: 'Please, select a option:',
    add_bitcoins: 'Add Crypto to your Futuur account',
    add_currency: 'Transfer to your Futuur ${this.currency} Wallet',
    add_funds: 'Add Funds',
    buy_btc: 'Buy Crypto',
    buy_crypto: 'Buy Crypto',
    copy_link: 'Copy Address',
    wallet_address: 'Your Futuur ${this.currency} Wallet Address',
    currency_list: 'Currency List',
    current_balance: 'Current Balance:',
    deposit_being_processed: 'Your deposit is being processed',
    go_to_home: 'Go to Home',
    go_to_payment_gateway: 'Go to Skrill',
    is_being_processed: 'is being processed',
    minimum_deposit: 'Minimum deposit is 1.0',
    proceed_to_payment: 'Proceed to Payment Method',
    question_deposit_amount: 'How much do you want to deposit?',
    send_money: 'Send ${this.currency} to this address to add funds to your account',
    third_party_payment: 'Third Party Payment Method',
    view_all_transactions: 'My Transactions',
    we_use: 'We use',
    your_deposit_of: 'Your deposit of',
    see_withdraw_fees: 'See withdrawal fees',
    see_withdraw_fees_here: 'here',
    we_use_complement:
      'services to safely and securely process payments. You will be redirected back to Futuur once your payment is complete. See you soon!',
    tooltip_title: '${this.currencyLabel} Fees',
    tooltip_fee_1: 'A',
    tooltip_fee_2: 'fee is charged by our payments provider for each withdrawal.',
    tooltip_withdrawal: 'Minimum Withdrawal is',
    missing_data: {
      title: 'Please provide some additional information ',
      description:
        'In order to comply with regulations, we need to collect some basic information about you.  Futuur does not share or sell any date unless required by law.',
    },
    network: 'Blockchain Network',
    network_placeholder: 'Please select a network',
    make_sure_network:
      'Please ensure you are using the correct Network. Transfers made to the wrong Network may be lost.',
    notification: {
      title: 'Address copied!',
      body: 'The address of your Fuutur wallet has been copied.',
    },
    deposit: 'Deposit',
    deposit_crypto: 'Deposit Crypto',
    send_address_to_deposit: 'Send to this address to deposit into your account',
    select_a_currency: 'Select a currency',
    select_a_cryptocurrency: 'Select a cryptocurrency',
    cryptocurrency: 'Cryptocurrency',
    amount: 'Amount',
    select_realy_money: {
      fiat: {
        title: 'USD, EUR, BRL and others',
        description: 'Credit card, bank transfer, or other payment methods',
      },
      crypto: {
        title: 'Deposit Crypto',
        description: ' Choose the cryptocurrency of your choice',
      },
      more: 'more',
    },
    astropay_explained: 'Payment will be processed by <br/> Astropay, our payment partner',
    and_other: 'and others',
    deposit_status: {
      APPROVED: {
        title: 'Deposit made successfully!',
        description: 'Funds are now available in your wallet.',
        view_transactions: 'View my transactions',
        ok: 'Okay, got it',
      },
      SUCCESS: {
        title: 'Deposit made successfully!',
        description: 'Your deposit of <b>${this.amount} ${this.currency}</b> is already available in your wallet.',
        view_transactions: 'View my transactions',
        ok: 'Start forecasting',
      },
      PENDING: {
        title: 'Deposit in proggress',
        description: 'Your deposit is being processed, you will receive an email as soon as it is confirmed.',
        ok: 'Okay, got it',
      },
      CANCELLED: {
        title: 'Deposit cancelled!',
        description: 'Your deposit has been cancelled, please try again.',
        ok: 'Okay, got it',
      },
      ERROR: {
        title: 'Forbidden country',
        description:
          'Unfortunately your country is not supported for real-money deposits or betting. However you may continue to bet in play-money. If your country of residence has changed, you can update it <a class="link" href="/p/personal-info-update" >here</a>.',
        ok: 'Okay, got it',
      },
    },
    withdraw_status: {
      waiting_user_confirmation: {
        title: 'You have a pending withdraw',
        description:
          'You already have a pending withdrawal of <b>${this.amount}</b>. Please confirm this withdrawal on the email we’ve sent you.',
        cta: 'Resend E-mail',
      },
      waiting_user_confirmation_still: {
        title: "We've sent you a <b>confirmation email</b>",
        description: 'Please click on the confirmation link in the email within 48 hours to complete your withdrawal.',
        cta: 'Okay, got it!',
      },
      waiting_user_form: {
        title: 'You have an incomplete withdrawal',
        description:
          'You already requested a withdrawal of <b>${this.amount}</b> but did not finished the whole proccess. What do you want to do?',
        cta: 'Resume this withdrawal',
      },
      cancel_withdraw: {
        cta: 'Cancel withdrawal',
        toast_message_title: 'Withdrawal canceled',
      },
      resend_sent: {
        toast_message_title: 'E-mail sent',
        toast_message_description: 'Please check your inbox and confirm your withdrawal',
      },
      pending: {
        title: 'You have an in-progress withdrawal',
        description:
          'You have an in-progress withdrawal of <b>${this.amount}</b>. your withdrawal is beeing processed and soon will be complete. you can still cancel this withdrawal if you want.',
        cta: 'Keep this withdrawal',
      },
    },
    other_deposit_methods_title: 'Other options',
    other_deposit_methods_description: 'Credit card, bank transfer, PicPay or other payment methods',
    direct_deposit_description: 'Your deposit will be processed in minutes',
    pix_only_for_brazil: 'This option is available only for Brazilan resident',
    pix_only_for_mexico: 'This option is available only for mexican resident',
    pix_key_notification: {
      title: 'Pix key Copied',
      description: 'The pix key is copied, you can use it to make a deposit',
    },
    pix_qr_title: 'Deposit via Pix',
    pix_qr_description:
      'Access your bank, opt for Pix payment, and complete your transaction. Code valid for 30 minutes.',

    SPEI_key_notification: {
      title: 'SPEI key Copied',
      description: 'The SPEI key is copied, you can use it to make a deposit',
    },
    SPEI_qr_title: 'Deposit via SPEI',
    SPEI_qr_description: 'Please complete the transfer to the following CLABE',
    SPEI_copy: 'Copy the code below to complete your transfer',
  },
  how_to_buy_bitcoins: {
    buy_btc: 'Buy Cryptocurrencies',
    intro_text:
      'If you need to purchase crypto, you can do so on any of the following exchanges. You can then deposit to Futuur by making a transfer from the exchange to your Futuur wallet address.',
    info_pix: 'PIX for instant transfers',
    info_bank_transfer_deposit: 'Bank transfer and debit card',
    info_direct_purchase: 'Book or Direct Purchase',
    info_purchase_methods_1: 'Accepts PagSeguro, Paypal and Mercado Pago',
    info_find_exchange_p2p: 'Find best exchange on P2P market',
    info_pay_credit_card: 'Pay with Credit Card',
    info_directly_futuur_wallet: 'Directly to your Futuur Wallet',
    info_check_country: 'Check for country and State eligibility',
    info_buy_sell_bitcoins: 'Buy and sell Cryptocurrencies',
    info_bank_account_debit_credit: 'Bank account, credit card and debit card',
    go_to: 'Go to ${this.provider}',
  },
  kyc: {
    status: {
      no_need: 'No verification needed',
      initialized: 'In Progress',
      refused: 'Refused',
      approved: 'Approved',
    },
    action: 'Start',
    paragraph: {
      refused: 'Your Identity verification was refused. Please try again.',
      requested: 'Raise your deposit limits by completing our KYC process.',
    },
  },
  kyc_warning: {
    text: 'In order to keep Futuur safe we need you to send your identity declaration. It will take no more than 2 minutes.',
    small_title: 'Oops! You are locked for making new bets',
    start_id_verification: 'Start ID Verification',
    verify_your_identity: 'Verify your identity',
    unlock_deposit_warning:
      'Unlock higher deposit limits and improve overall security to your account. Start the KYC process now.',
  },
  kyc_form: {
    first_name: 'First name',
    last_name: 'Last name',
    country: 'Country of residence',
    placeholder_country: 'Country of residence',
    date: 'Date of birth',
    submit: 'Submit',
    error_first_name: 'Minimum 5 characters required',
    address: 'Address',
    placeholder_adress: '456 Oak Avenue, Los Angeles, CA 90001',
    success_message: 'Personal info updated',
  },
  withdraw: {
    currency_list: 'Select Currency',
    add_skrill_account: 'Add a Skrill Account',
    available_withdraw_methods: 'Available Withdraw Methods',
    wallet_address: '${this.currency} Address',
    wallet_address_placeholder: 'Your ${this.currency} wallet address',
    choose_funds_destination: "Choose your funds' destination",
    continue: 'Continue',
    current_balance: 'Current Balance:',
    how_much_send_via: 'How much do you want to send via',
    how_much: 'How much ${this.currency} do you want to withdraw?',
    is_being_processed: 'is being processed',
    minimum_withdraw_crypto: 'Minimum Withdrawal is ${this.value}',
    money_on_the_way: 'Money is on the way!',
    invalid_address: 'Invalid address',
    invalid_address_warning: 'This does not appear to be a properly formatted address for ${this.currencyName}.',
    payment_being_processed: "Your withdraw is being processed, we will let you know once it's done.",
    tax_withdraw_crypto: 'Please note that a ${this.tax} fee is charged by our payments provider on each withdrawal',
    send: 'Send',
    view_all_transactions: 'View All Transactions',
    withdraw_done: 'Your money is on the way!',
    withdraw_funds: 'Withdraw Funds',
    withdraw_value: 'Withdraw Value',
    withdraw: 'Withdraw',
    your_amount_of: 'Your withdraw of ',
    user_no_account_for_currency: 'You need to add funds to this currency first',
    values_in_mbtc: 'Enter your withdrawal amount in miliBitcoin (1 mBTC = 0.001 BTC)',
    warning_usdc: 'Only use ${this.blockchain} network for this transaction, otherwise your funds may be lost.',
    currently_have: 'You currently have ',
    min_value: 'Minimum withdrawal amount is ${this.min_value}',
    blockchain_network: 'Blockchain Network',
    ensure_network:
      'Please, ensure you are using the correct Network. Transfers made to the wrong network may be lost.',
    withdraw_funds_to_astropay: 'Withdrawal funds to your Astropay Account',
    withdraw_funds_to_pix: 'Withdrawal funds to your Bank Account via PIX',
    pix_key: 'Enter your Pix Key',
    payment_will_processed_with_astropay: 'Payment will be processed by Astropay, our payment partner',
    amount: 'Amount',
    edit_information: 'Edit informations',
    cpf: 'What is your CPF?',
    random_key: 'Random Key',
    cell_phone: 'Cell Phone',
    resume: 'Resume',
    pix_key_type: 'Pix Key type',
    review_transfer: 'Review your transfer:',
    withdrawal_under_min_amount: 'Withdrawal under minimum amount',
  },
  withdraw_email_received: {
    title: 'Confirmation email sent, check your inbox',
    text: 'Please click the link on the confirmation email to verify your request and initiate the withdrawal process',
    close: 'Close',
  },
  withdraw_validation: {
    no_longer_valid: 'Your withdraw of <b>${this.amount}</b> <span>${this.currency}</span> is no longer valid',
    initiated: 'Your withdraw has been initiated.',
    receive_notification: 'You will receive a notification and e-mail when the transaction is complete.',
    request_another: 'Please, place another request if you wish to withdraw funds from your account',
    error_p1:
      'An error occurred while trying to validate your request. Your token might be expired or the request is already validated.',
    error_p2: 'If you need additional support, please contact us.',
    go_to_markets: 'Go to Markets',
    view_transactions: 'View all my transactions',
    request_verified: 'Request Verified!',
    request_canceled: 'This Request was cancelled!',
    title_error: 'Ops... something wrong',
    verifying_request: 'Verifying request',
  },
  chart: {
    timeframes: {
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      all_time: 'All Time',
    },
  },
  wallet: {
    balance: 'Balance',
    add_funds: 'Add funds',
    withdraw: 'Withdraw',
    transactions: 'Transactions',
    date_format: 'd MMMM, yyyy',
    all_time: 'All time',
    ooms_balance: 'Ooms Balance',
    wallet_name: '${this.currency} Wallet',
    pending_withdraw:
      `You have a pending withdrawal of ` + '${this.amount}.' + ` Approval typically occurs within 24 hours.`,
    all: 'All actions',
    incomes: 'Credits',
    outcomes: 'Debits',
    export: 'Export',
    account_activity: 'Account Activity',
    current_balance: 'Current balance',
    wallet: 'Wallet',
    action_wager_purchase: 'Bet',
    action_wager_sale: 'Sale',
    action_fee: 'Comission',
    action_wager_win: 'Win',
    action_wager_lose: 'Lose',
    action_deposit: 'Deposit',
    action_withdraw: 'Withdraw',
    action_initial_balance: 'Initial',
    action_question_cancelled: 'Cancelled',
    action_question_reversion: 'Reverted',
    action_transaction_reversion: 'Reverted',
    action_manual_transaction: 'Manual',
    action_withdrawal_request: 'Withdraw resquest',
    action_question_settlement: 'Settlement',
    action_pending_withdraw: 'Pending withdraw',
    action_cancel_withdraw: 'Cancel Request',
    action_other: 'Other',
    no_result: "We didn't find any results",
  },
  currency_selector: {
    title: 'Currency Selector',
    intro: 'Select the markets you want to explore and pick the currency to make forecasts with',
    about: 'About',
    info: 'Changing your active currency will change the currency you use to make bets on Futuur. Choose Ooms to bet using play money, or Cryptocurrencies to bet using real money.',
    dismiss: 'Dismiss',
    add_funds: 'Add funds',
    add_more: 'Add More',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    confirm: 'Confirm active currency',
    continue: 'Continue',
    cancel: 'Cancel',
    play_money_markets: 'Play Money Markets',
    real_money_markets: 'Real Money Markets',
    play_money_title: 'Ooms',
    real_money_title: 'Real Money',
    play_money: 'Play Money',
    view_only: 'View only',
    real_money: 'Real Money',
    tooltip_title: 'How it works',
    tooltip_description:
      'The Currency Selector determines which markets you will see (real-money or play money), as well as which currency you are betting with.',
    check_wagering_forbidden_country:
      'While we do not offer real-money wagering on your jurisdiction, you can access them on a view-only basis',
    check_web_version:
      'To check for real-money wagering eligibility go to our web-based version in <a href="https://futuur.com" target="_blank" >https://futuur.com</a>',
    please_register: 'To place bets and receive 10000 Ooms, please register.',
    register: 'Register',
    sign_in: 'Sign in',
    add_or_remove_currency: 'Add or remove currency',
    intro_app:
      'You are only allowed to view the Ooms Markets based on your jurisdiction. To check for Real Money Markets availability, please <a href="https://futuur.com" target="_blank" >View the Web Version</a>.',
    check_availability: 'check for availability',
    view_web_version: 'View web version',
    no_funds: 'Please deposit funds to bet on real-money markets',
    fromForbiddenCountry:
      'You are accessing Futuur from a Restricted Jurisdiction and are not able to bet on real-money markets.  However, you can view real-money markets and forecasts, and you are welcome to bet in our Play-Money markets!',
    confirm_button: 'Confirm change',
    change_for: 'Change for ',
    currency_changed: 'Currency selected changed',
    currency_changed_description: 'You are now seeing ${this.currency_mode} markets.',
    offline_description:
      'You are viewing crowd-generated forecasts from ${this.currency_mode} markets. To view forecasts from ${this.other_mode} markets, use the toggle below.',
    add_another_currency: 'Add another currency',
    click_to_select: 'Click to select default currency to bet',
    sigin_in: 'To make bets, please sign in :',
    no_funds_yet: 'No funds yet',
  },
  onboarding_app: {
    button: {
      b1: "Let's go!",
      b2: 'Money! Great!',
      b3: '100% Understood',
      b4: 'Got it!',
      b5: 'Ok, ready to go!',
    },
    slide_1: {
      l1: 'Hello',
      l2: 'Welcome to ',
      l3: 'Your career as a forecaster',
      l4: 'is about to begin!',
    },
    slide_2: {
      l1: 'On Futuur, you predict the outcomes of future events using our in-house currency, the Oom (ø).',
      l2: 'You will start with 10,000ø.',
      l3: 'Spend them wisely!',
    },
    slide_3: {
      l1: 'The price for each outcome represents the percentage chance that the outcome will occur, as determined by the marketplace of forecasters.',
    },
    slide_4: {
      l1: 'If the outcome resolves in your favor, you will receive 1ø for each share purchased.',
      l2: 'Pro tip: ',
      l3: 'if you think the likelihood is higher than the price, it’s a smart bet to purchase that outcome!',
    },
    slide_5: {
      l1: 'Prices fluctuate as forecasters make their bets.',
      l2: 'You can sell your position at ',
      l3: 'any time',
      l4: ' — you don’t need to wait for a question to be resolved.',
    },
  },
  onboarding_tour: {
    button_1: "Let's go!",
    button_2: 'Got it!',
    skip: 'Skip intro',
    continue: 'Continue',
    lets_go: "Let's go!",
    ready_to_predict: 'Ready to Predict!',

    intro_real_money: {
      title: 'Hello, Forecaster!',
      p1: 'Welcome to Futuur, where you can make money with what you know, or just play for fun!',
      p2: 'Join us for a brief tour',
    },

    intro_play_money: {
      title: 'Hello, Forecaster!',
      p1: 'Welcome to Futuur, where you can bet on pretty much anything, and help create better predictions for everyone.',
      p2: 'Join us for a brief tour!',
    },

    ooms_slide: {
      title: "You've Got Ooms!",
      p1: 'Every forecaster starts with 10,000ø in Ooms, our play-money currency.',
      p2: 'You can’t buy more Ooms, so wager wisely to build your rep & rise up the leaderboard!',
    },

    make_bet: {
      title: 'Find a market & make a bet',
      p1: 'For each of an event’s possible outcomes, you’ll see a  percentage',
      p2: 'That represents the crowd’s current best guess of the likelihood of it occurring.',
    },

    buy_and_sell: {
      title: 'Buy and sell',
      p1: 'As forecasters place their bets, prices go up and down accordingly.',
      p2: 'You can sell your bets at any time to take a profit or cut your losses.',
    },

    add_funds: {
      title: 'Add funds to make it real!',
      p1: 'Make a deposit in any of the major cryptocurrencies.',
      p2: 'There is no deposit minimum, and you can withdraw your funds at any time!',
    },
  },
  pwa_installation: {
    title: 'Add Futuur to home screen?',
    subtitle: 'For quick and easy access',
    actionButton: 'Add to home screen',
  },
  ask_notification_permission: {
    header: 'Browser notification',
    title: 'Allow Futuur to send browser notification',
    subtitle: 'Stay updated on the latest Futuur markets!',
    actionButton: 'Allow Browser Notification',
  },
  error: {
    text: '<b>Unexpected issue encountered ! <br/> We&apos;re actively working to resolve this.</b> <br/> <br/>  In the meantime, you can save the fish or click the button to return to the feed.',
    button: 'Go to feed',
  },
}
