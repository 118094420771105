import React from 'react'
import NotificationTemplate from 'notifications/components/notification-template'
import { Kyc } from 'common/components/icons'
import { formatNumber } from 'common/util/localizeNumber'
import { useSelector } from 'react-redux'
import { selectActiveLanguage } from 'store/app'
import { useCurrencies } from 'common/hooks'
import { i18n } from 'common/locale/i18n'

const NotificationItem = params => {
  const locale = useSelector(selectActiveLanguage()) || 'en'
  const currencies = useCurrencies().currencies

  const defaultData = {
    title: params.notification.message,
    paragraph: '',
  }
  const notificationData = notification => {
    const shares = notification.related_object?.shares || 0
    const currency = notification.related_object?.currency
    const questionTitle = notification.related_object ? notification.related_object.question?.title : ''
    const betOn = notification.related_object?.outcome && notification.related_object?.outcome.title

    switch (notification.type_code) {
      case 'question_cancelled':
      case 'question_is_live':
        return {
          title: (
            <>
              {i18n("notifications.market_is")} <b>{notification.type_code === 'question_cancelled' ? i18n("notifications.cancelled") : i18n("notifications.live")}</b>
            </>
          ),
          paragraph: notification.message,
        }

      case 'wager_lost':
      case 'wager_won':
        return {
          title: (
            <>
              {i18n("notifications.you") } 
              <b style={{ color: notification.type_code === 'wager_lost' ? '#DC1F0B' : '#005B23' }}>
                {notification.type_code === 'wager_lost' ? 'Lost' : 'Won'}
              </b>{" "}
              { i18n("notifications.the_bot_on")}<b>{betOn}</b> {i18n("notifications.for")}{" "}
              <b>
                {formatNumber(shares, locale, 2, currencies[currency].display_decimal_places)}{' '}
                {currency === 'OOM' ? 'ø ' : currency}
              </b>
            </>
          ),
          paragraph: questionTitle,
        }

      case 'comment_made':
        return {
          title: (
            <>
              <b>{notification.user.username} {i18n("notifications.commented")} </b>
              {notification.message}
            </>
          ),
          paragraph: notification.related_object?.title,
          image: notification.user.picture,
        }
      case 'bets_blocked_by_kyc':
      case 'deposit_blocked_by_kyc':
        return {
          title: notification.message,
          icon: <Kyc />,
        }
      case 'kyc_refused':
        return {
          title: notification.message,
          paragraph: (
            <a className="link" href={notification.link}>
              Review your document
            </a>
          ),
          icon: <Kyc />,
        }
      case 'kyc_approved':
        return {
          title: notification.message,
          icon: <Kyc />,
        }
      case 'withdrawal_success':
      case 'real_money_callout':
      case 'withdrawal_request':
      case 'deposit_success':
      case 'withdrawal_pending':
        return {
          title: notification.message,
          icon: (
            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.9993 27.3337C6.63535 27.3337 0.666016 21.3643 0.666016 14.0003C0.666016 6.63633 6.63535 0.666992 13.9993 0.666992C21.3633 0.666992 27.3327 6.63633 27.3327 14.0003C27.3327 21.3643 21.3633 27.3337 13.9993 27.3337ZM13.9993 24.667C16.8283 24.667 19.5414 23.5432 21.5418 21.5428C23.5422 19.5424 24.666 16.8293 24.666 14.0003C24.666 11.1713 23.5422 8.45824 21.5418 6.45785C19.5414 4.45747 16.8283 3.33366 13.9993 3.33366C11.1704 3.33366 8.45726 4.45747 6.45688 6.45785C4.45649 8.45824 3.33268 11.1713 3.33268 14.0003C3.33268 16.8293 4.45649 19.5424 6.45688 21.5428C8.45726 23.5432 11.1704 24.667 13.9993 24.667ZM9.33268 16.667H16.666C16.8428 16.667 17.0124 16.5968 17.1374 16.4717C17.2624 16.3467 17.3327 16.1771 17.3327 16.0003C17.3327 15.8235 17.2624 15.6539 17.1374 15.5289C17.0124 15.4039 16.8428 15.3337 16.666 15.3337H11.3327C10.4486 15.3337 9.60078 14.9825 8.97566 14.3573C8.35054 13.7322 7.99935 12.8844 7.99935 12.0003C7.99935 11.1163 8.35054 10.2684 8.97566 9.6433C9.60078 9.01818 10.4486 8.66699 11.3327 8.66699H12.666V6.00033H15.3327V8.66699H18.666V11.3337H11.3327C11.1559 11.3337 10.9863 11.4039 10.8613 11.5289C10.7363 11.6539 10.666 11.8235 10.666 12.0003C10.666 12.1771 10.7363 12.3467 10.8613 12.4717C10.9863 12.5968 11.1559 12.667 11.3327 12.667H16.666C17.5501 12.667 18.3979 13.0182 19.023 13.6433C19.6482 14.2684 19.9993 15.1163 19.9993 16.0003C19.9993 16.8844 19.6482 17.7322 19.023 18.3573C18.3979 18.9825 17.5501 19.3337 16.666 19.3337H15.3327V22.0003H12.666V19.3337H9.33268V16.667Z"
                fill="white"
              />
            </svg>
          ),
        }
      case 'follow_created':
        return defaultData

      case 'amount_earned':
        return defaultData

      default:
        return defaultData
    }
  }

  return <NotificationTemplate {...params} notificationData={notificationData(params.notification)} />
}

export default NotificationItem
