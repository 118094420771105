import Button from 'common/ui/button'
import React from 'react'
import './email-not-confirmed.scss'
import { CheckIcon } from 'common/components/icons'
import { i18n } from 'common/locale/i18n'
import { useModal } from '../ModalProvider'
import { useNavigate } from 'react-router-dom'
const DepositSuccess = ({ data }) => { 
  const { hideModal } = useModal()
  const navigate = useNavigate()
  return (
    <div className="view-crypto-cash-in__deposit_status">
      <i>
        <CheckIcon width="24px" height="24px" />
      </i>
      <div className="view-crypto-cash-in__deposit_status--title">{i18n(`cash_in.deposit_status.SUCCESS.title`)}</div>
      <div
        className="view-crypto-cash-in__deposit_status--description"
        dangerouslySetInnerHTML={{
          __html: i18n(`cash_in.deposit_status.SUCCESS.description`, { amount: data.amount, currency: data.currency }),
        }}></div>
      <Button size="large" onClick={()=>{
        navigate(-1)
        hideModal()
      }}>
        {i18n(`cash_in.deposit_status.SUCCESS.ok`)}
      </Button>
    </div>
  )
}
export default DepositSuccess
