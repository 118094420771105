import { i18n } from 'common/locale/i18n'
import React from 'react'
import './ActiveMarkets.scss'
import FilterDropdown from 'common/components/filter-dropdown/FilterDropdown'
import { urlSearchParams } from 'common/util/urlSearchParams'
import { useNavigate } from 'react-router-dom'
import Device from 'common/util/Device'
import { useEffect } from 'react'
import { useState } from 'react'
import { useABTests } from 'common/hooks'
import { selectAbTestsStatus } from 'store/app'
import constants from 'app/constants'
import { useSelector } from 'react-redux'

const ActiveMarkets = ({ numberOfActiveBets = 0, isRealMoney = false, isLoading, filters }) => {
  const [queryParams, setQueryParams] = useState({})
  const abTestsStatus = useSelector(selectAbTestsStatus)
  const { getABTestSegment } = useABTests()
  useEffect(() => {
    /**
     * An async function to get query parameters from the URL,
     * and set a default ordering if not present.
     */
    const getQueries = () => {
      const _queryParams = Object.fromEntries(new urlSearchParams(location.search))

      const feedDefaultSort = getABTestSegment('feed-sort') || ''

      if (!_queryParams.ordering) {
        _queryParams.ordering = feedDefaultSort
      }

      return _queryParams
    }

    if (abTestsStatus === constants.COMPLETE) {
      const query = getQueries()
      setQueryParams(query)
      // updateUrlParams('ordering', query.ordering)
    }
  }, [location.search, abTestsStatus]) // Empty dependency array means this effect runs once when the component mounts

  const navigate = useNavigate()
  const updateUrlParams = (paramName, paramValue) => {
    const currentParams = new URLSearchParams(location.search)
    currentParams.set(paramName, paramValue)
    navigate({ search: currentParams.toString() })
  }
  const sortByOptions = [
    { value: '-created_on', text: i18n('markets.most_recent') },
    { value: 'bet_end_date', text: i18n('markets.bet_end_date') },
    { value: '-wagers_count', text: i18n('markets.number_of_bets') },
    { value: '-volume', text: i18n('markets.volume') },
    { value: '-relevance', text: i18n('markets.relevance') },
  ]
  return (
    <>
      <div className="active-markets row">
        <div className="active-markets--left">
          {isRealMoney ? i18n('active_markets.title_real_money') : i18n('active_markets.title_play_money')}
          {!isLoading && !Device.isMobileSize && (
            <span className="active-markets__quantity">
              {numberOfActiveBets} {i18n('page_title.markets')}
            </span>
          )}
        </div>
        <div className="active-markets__mobileRow">
          {!isLoading && Device.isMobileSize && (
            <span className="active-markets__quantity">
              {numberOfActiveBets} {i18n('page_title.markets')}
            </span>
          )}
          {
            <FilterDropdown
              type="list"
              // placeholder={queryParams.ordering ? null : i18n('markets.sort_by')}
              keepPlaceholder={true}
              value={queryParams.ordering || '-relevance'}
              list={sortByOptions}
              arrow="outlined"
              highlightSelected
              className="market-filter"
              onChange={value => updateUrlParams('ordering', value)}
            />
          }
        </div>
        {Device.isMobileSize && filters}
      </div>
      {!Device.isMobileSize && filters}
    </>
  )
}

export default ActiveMarkets
