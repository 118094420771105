import React from 'react'
import './Accordion.scss'
import { Crevron } from 'common/components/icons'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
const Accordion = ({ title, description, isOpen = null, setIsOpen = () => {}, content, ...props }) => {
  const description_container = useRef()
  const [height, setHeight] = useState(0)
  const [slefIsOpen, setSlefIsOpen] = useState(isOpen === null ? false : isOpen)
  useEffect(() => {
    console.log(slefIsOpen, description_container.current.scrollHeight)
    setHeight(slefIsOpen ? description_container.current.scrollHeight : 0)
  }, [content, description,slefIsOpen])
  useEffect(() => {
    setSlefIsOpen(isOpen === null ? false : isOpen)
  }, [isOpen])
  return (
    <>
      <div
        className={'accordion ' + props.className}
        onClick={() => {
          if (isOpen === null) {
            setIsOpen()
            setSlefIsOpen(v => (v === null ? true : !v))
          } else setIsOpen()
        }}>
        <div className="accordion__title">
          <h6>{title}</h6>
          <Crevron width="14px" direction={slefIsOpen ? 'up' : 'down'} fillcolor="currentColor" />
        </div>
      
      </div>
       <div className="accordion__description" ref={description_container} style={{ maxHeight: height + 'px' }}>
       {description && <p dangerouslySetInnerHTML={{ __html: description }}></p>}
       {content}
         </div>
    </>
  )
}

export default Accordion
