import requestService from 'common/services/request'
import constants from 'app/constants'
import mixpanelService from 'app/analytics/Mixpanel'
import analyticsService from 'common/services/analytics'
import wagerService from 'prediction/services/wager'
import { createSlice } from '@reduxjs/toolkit'
import { transformArrayIntoMap } from 'common/util/mapArray'
import { fetchCurrentUser } from 'store/account'
import { fetchMarketById } from 'store/markets'
import currencies, { isRealMoney as isCurrencyRealMoney, getMarketType } from 'common/util/currencies'
import { subscribeToDiscussion } from 'api/community'
import { toastService } from 'api/toast'

const initialState = {
  pool: { data: {}, status: {}, error: {} },
  summary: {
    data: {},
    status: constants.IDLE,
    error: null,
  },
  activeBets: {
    [constants.REAL_MONEY]: { data: [], status: constants.IDLE, error: null },
    [constants.PLAY_MONEY]: { data: [], status: constants.IDLE, error: null },
  },
  pastBets: {
    [constants.REAL_MONEY]: { data: [], status: constants.IDLE, error: null },
    [constants.PLAY_MONEY]: { data: [], status: constants.IDLE, error: null },
  },
  history: {
    data: {},
    status: {},
    error: {},
    pagination: {},
  },
  userBets: {
    [constants.REAL_MONEY]: { data: {}, status: {}, error: {}, pagination: {} },
    [constants.PLAY_MONEY]: { data: {}, status: {}, error: {}, pagination: {} },
  },
  profileBets: {
    data: {},
    status: constants.IDLE,
    pagination: {},
    profile_id: null,
    error: null,
  },
  lastBet: { data: {}, status: constants.IDLE, error: null },
}

const emptyArray = []
const emptyObj = {}

// Reducers / Slices

const wagers = createSlice({
  name: 'wagers',
  initialState,
  reducers: {
    getCurrentUserSummaryStart: state => {
      state.summary.status = constants.LOADING
    },
    getCurrentUserSummarySuccess: (state, action) => {
      const { data } = action.payload
      state.summary.status = constants.COMPLETE
      state.summary.data = transformArrayIntoMap(data || [], 'currency')
    },

    getCurrentUserSummaryError: (state, action) => {
      const { error } = action.payload
      state.summary.status = constants.ERROR
      state.summary.error = error
    },

    getCurrentUserBetsStart: (state, action) => {
      const { isRealMoney, isActiveBets } = action.payload
      const marketType = getMarketType(isRealMoney)
      const bets = isActiveBets ? state.activeBets[marketType] : state.pastBets[marketType]
      bets.status = constants.LOADING
    },

    getCurrentUserBetsSuccess: (state, action) => {
      const { isRealMoney, isActiveBets, data, withRefresh } = action.payload
      const marketType = getMarketType(isRealMoney)
      const bets = isActiveBets ? state.activeBets[marketType] : state.pastBets[marketType]

      if (withRefresh) {
        state.pool.data = transformArrayIntoMap(data.results)
        if (isActiveBets) state.activeBets[marketType].data = [...data.results.map(el => el.id)]
        else state.pastBets[marketType].data = [...data.results.map(el => el.id)]
      } else {
        state.pool.data = { ...state.pool.data, ...transformArrayIntoMap(data.results) }
        // bets.data.push(...data.results.map(el => el.id))
        if (isActiveBets)
          state.activeBets[marketType].data = state.activeBets[marketType].data.concat([
            ...data.results.map(el => el.id),
          ])
        else
          state.pastBets[marketType].data = state.pastBets[marketType].data.concat([...data.results.map(el => el.id)])
      }
      bets.status = constants.COMPLETE
      bets.pagination = data.pagination
    },

    getCurrentUserBetsError: (state, action) => {
      const { isRealMoney, isActiveBets, error } = action.payload
      const marketType = getMarketType(isRealMoney)
      const bets = isActiveBets ? state.activeBets[marketType] : state.pastBets[marketType]

      bets.error = error
      bets.status = constants.ERROR
    },

    getUserBetStart: (state, action) => {
      const { betId } = action.payload
      state.pool.status[betId] = constants.LOADING
    },

    getUserBetSuccess: (state, action) => {
      const { bet } = action.payload
      state.pool.status[bet.id] = constants.COMPLETE
      state.pool.data[bet.id] = bet
    },

    getUserBetError: (state, action) => {
      const { betId, error } = action.payload
      state.pool.error[betId] = error
      state.pool.status[betId] = constants.ERROR
    },

    getUserWagersStart: (state, action) => {
      const { userId } = action.payload
      state.profileBets.profile_id = userId
      state.profileBets.status = constants.LOADING
    },

    getUserWagersSuccess: (state, action) => {
      const { results, pagination } = action.payload
      state.profileBets.data = results
      state.profileBets.pagination = pagination
      state.profileBets.status = constants.COMPLETE
    },
    resetUserWagersSuccess: state => {
      state.profileBets.data = {}
      state.profileBets.pagination = {}
      state.profileBets.status = constants.IDLE
    },

    getUserWagersError: (state, action) => {
      const { error } = action.payload
      state.profileBets.error = error
      state.profileBets.status = constants.ERROR
    },

    sellBetStart: (state, action) => {
      const { betId } = action.payload
      state.pool.status[betId] = constants.SAVING
      state.pool.error[betId] = null
    },

    sellBetSuccess: (state, action) => {
      const { bet } = action.payload
      state.pool.status[bet.id] = constants.COMPLETE
      state.pool.data[bet.id] = bet

      const marketType = getMarketType(bet.currency !== 'OOM')
      if (state.activeBets[marketType].data.length) {
        state.activeBets[marketType].data = state.activeBets[marketType].data.filter(id => id !== bet.id)
      }

      if (state.pastBets[marketType].data.length) {
        state.pastBets[marketType].data = [bet.id, ...state.pastBets[marketType].data]
      }
    },

    sellBetError: (state, action) => {
      const { betId, error } = action.payload
      state.pool.status[betId] = constants.ERROR
      state.pool.error[betId] = error
    },

    getBetHistoryStart: (state, action) => {
      const { questionId } = action.payload
      state.history.status[questionId] = constants.LOADING
    },

    getBetHistorySuccess: (state, action) => {
      const { questionId, bets, pagination, clear } = action.payload

      state.history.status[questionId] = constants.COMPLETE
      if (!state.history.data[questionId]) {
        state.history.data[questionId] = []
      }
      if (clear) state.history.data[questionId] = [...bets]
      else state.history.data[questionId].push(...bets)
      state.history.pagination[questionId] = pagination
    },

    getBetHistoryError: (state, action) => {
      const { questionId, error } = action.payload
      state.history.error[questionId] = error
    },

    resetBetHistory: (state, action) => {
      const { questionId } = action.payload
      state.history.error[questionId] = null
      state.history.pagination[questionId] = null
      state.history.data[questionId] = []
      state.history.status[questionId] = constants.IDLE
    },

    clearLastBet: state => {
      state.lastBet = { data: {}, status: constants.IDLE, error: null }
    },

    createBetStart: state => {
      state.lastBet.status = constants.SAVING
    },

    createBetSuccess: (state, action) => {
      const { bet } = action.payload
      state.lastBet.data = bet
      state.lastBet.status = constants.COMPLETE
      state.pool.data[bet.id] = bet
    },

    createBetError: (state, action) => {
      const { error } = action.payload
      state.lastBet.error = error
      state.lastBet.status = constants.ERROR
    },

    addActiveBetId: (state, action) => {
      const { isRealMoney, bet } = action.payload
      const marketType = getMarketType(isRealMoney)

      if (state.activeBets[marketType].status === constants.COMPLETE) {
        state.activeBets[marketType].data.unshift(bet.id)
      }
    },
    refreshBet: (state, action) => {
      const { bet } = action.payload
      state.activeBets[constants.REAL_MONEY].data = {
        ...state.activeBets[constants.REAL_MONEY].data.find(b => b.id === bet.id),
        ...bet,
      }
      state.pastBets[constants.REAL_MONEY].data = {
        ...state.pastBets[constants.REAL_MONEY].data.find(b => b.id === bet.id),
        ...bet,
      }
      state.activeBets[constants.PLAY_MONEY].data = {
        ...state.activeBets[constants.PLAY_MONEY].data.find(b => b.id === bet.id),
        ...bet,
      }
      state.pastBets[constants.PLAY_MONEY].data = {
        ...state.pastBets[constants.PLAY_MONEY].data.find(b => b.id === bet.id),
        ...bet,
      }
    },

    getUserBetsStart: (state, action) => {
      const { isRealMoney, user } = action.payload
      if (!user) {
        return
      }

      const marketType = getMarketType(isRealMoney)
      state.userBets[marketType].status[user.id] = constants.LOADING
    },

    getUserBetsSuccess: (state, action) => {
      const { isRealMoney, user, results, pagination } = action.payload
      if (!user) {
        return
      }

      const marketType = getMarketType(isRealMoney)
      if (!state.userBets[marketType].data[user.id]) {
        state.userBets[marketType].data[user.id] = []
      }

      state.userBets[marketType].data[user.id] = [...state.userBets[marketType].data[user.id], ...results]
      state.userBets[marketType].pagination[user.id] = pagination
      state.userBets[marketType].status[user.id] = constants.COMPLETE
    },

    getUserBetsError: (state, action) => {
      const { isRealMoney, error, user } = action.payload

      const marketType = getMarketType(isRealMoney)
      state.userBets[marketType].data[user.id] = error
      state.userBets[marketType].status[user.id] = constants.ERROR
    },

    resetWagers: state => {
      for (const i in initialState) {
        state[i] = { ...initialState[i] }
      }
    },
  },
})

export const { getCurrentUserSummaryStart, getCurrentUserSummarySuccess, getCurrentUserSummaryError } = wagers.actions
export const { getCurrentUserBetsStart, getCurrentUserBetsSuccess, getCurrentUserBetsError } = wagers.actions
export const { getUserBetStart, getUserBetSuccess, getUserBetError } = wagers.actions
export const { getUserWagersStart, getUserWagersSuccess, getUserWagersError, resetUserWagersSuccess } = wagers.actions
export const { sellBetStart, sellBetSuccess, sellBetError } = wagers.actions
export const { resetBetHistory, getBetHistoryStart, getBetHistorySuccess, getBetHistoryError } = wagers.actions
export const { clearLastBet, createBetStart, createBetError, createBetSuccess, addActiveBetId, refreshBet } =
  wagers.actions
export const { getUserBetsStart, getUserBetsSuccess, getUserBetsError, resetWagers } = wagers.actions
export default wagers.reducer

// Selectors:
export const selectPortfolioSummary = () => state => {
  return state.wagers.summary.data
}

export const selectPortfolioSummaryStatus = () => state => {
  return state.wagers.summary.status
}

export const selectUserBets = (marketType, isActiveBets) => state => {
  const order = getBetsReference(state, marketType, isActiveBets).data 
  return order.map(id => state.wagers.pool.data[id])
}
export const selectUserActiveBetsByQuestion = () => state => {
  const order = [
    ...state.wagers.activeBets[constants.REAL_MONEY].data,
    ...state.wagers.activeBets[constants.PLAY_MONEY].data,
  ]
  return [...order.map(id => state.wagers.pool.data[id])]
}

export const selectUserBetsStatus = (marketType, isActiveBets) => state => {
  return getBetsReference(state, marketType, isActiveBets).status
}

export const selectUserBetsPagination = (marketType, isActiveBets) => state => {
  return getBetsReference(state, marketType, isActiveBets).pagination || {}
}

export const selectUserBetsError = (marketType, isActiveBets) => state => {
  return getBetsReference(state, marketType, isActiveBets).error
}

export const selectBetById = betId => state => {
  return state.wagers.pool.data[betId]
}

export const selectBetStatusById = betId => state => {
  return state.wagers.pool.status[betId] || constants.IDLE
}

export const selectBetErrorById = betId => state => {
  return state.wagers.pool.error[betId]
}

export const selectBetHistory = questionId => state => {
  return state.wagers.history.data[questionId] || emptyArray
}

export const selectBetHistoryPagination = questionId => state => {
  return state.wagers.history.pagination[questionId]
}

export const selectBetHistoryStatus = questionId => state => {
  return state.wagers.history.status[questionId]
}

export const selectBetHistoryByUser = (marketType, user) => state => {
  if (!user) {
    return emptyArray
  }

  return state.wagers.userBets[marketType].data[user.id] || emptyArray
}

export const selectBetHistoryStatusByUser = (marketType, user) => state => {
  if (!user) {
    return constants.IDLE
  }

  return state.wagers.userBets[marketType].status[user.id]
}

export const selectBetHistoryPaginationByUser = (marketType, user) => state => {
  if (!user) {
    return emptyObj
  }

  return state.wagers.userBets[marketType].pagination[user.id] || emptyObj
}

export const selectBetHistoryErrorByUser = (marketType, user) => state => {
  if (!user) {
    return null
  }

  return state.wagers.userBets[marketType].error[user.id]
}

export const selectLastBet = () => state => state.wagers.lastBet.data

export const selectLastBetStatus = () => state => state.wagers.lastBet.status

export const selectLastBetError = () => state => state.wagers.lastBet.error

export const selectUserWagers = () => state => state.wagers.profileBets.data

export const selectUserWagersStatus = () => state => state.wagers.profileBets.status

export const selectUserWagersError = () => state => state.wagers.profileBets.error

export const selectUserWagersPagination = () => state => state.wagers.profileBets.pagination

// Utils:
const getBetsReference = (state, marketType, isActiveBets) =>
  isActiveBets ? state.wagers.activeBets[marketType] : state.wagers.pastBets[marketType]

export const getAllCampains = () => JSON.parse(localStorage.getItem('campains') || '[]')

// Thunks:
/**
 * Fetch user portfolio summary. Contains information like profit, return and value invested.
 */
export const fetchPortfolioSummary = () => async dispatch => {
  dispatch(getCurrentUserSummaryStart())
  try {
    var response = await requestService.get(`/portfolio/summary/`)
  } catch (error) {
    dispatch(getCurrentUserSummaryError({ error }))
    return
  }
  const data = await response.json()
  dispatch(getCurrentUserSummarySuccess({ data }))
}

/**
 * Retrieve active user bets. `options` can contain an entry named `past_bets` instead, in order to
 * retrieve resolved and sold bets.
 */
export const fetchCurrentUserBets = options => async dispatch => {
  const isRealMoney = options.currency_mode === constants.REAL_MONEY
  const isActiveBets = options.status_group === 'active'
  delete options.currency_mode
  const params = requestService.parseFilters(options)

  if (options.withRefresh) dispatch(getCurrentUserBetsStart({ isRealMoney, isActiveBets }))
  try {
    var response = await requestService.get(`/portfolio/?${params.toString()}`)
  } catch (error) {
    dispatch(getCurrentUserBetsError({ error, isRealMoney, isActiveBets }))
    return
  }
  const data = await response.json()
  dispatch(getCurrentUserBetsSuccess({ data, isRealMoney, isActiveBets, withRefresh: options.withRefresh }))
  return data
}

/**
 * Get a single bet information using its id.
 */
export const fetchUserBet = betId => async dispatch => {
  dispatch(getUserBetStart({ betId }))
  try {
    var response = await requestService.get(`/wagers/${betId}/`)
  } catch (error) {
    dispatch(getUserBetError({ betId, error }))
    return
  }
  const bet = await response.json()
  dispatch(getUserBetSuccess({ bet }))
}

/**
 * Get a single bet information using its id.
 */
export const fetchPositionsByUser = (userId, options) => async dispatch => {
  const params = requestService.parseFilters(options)
  dispatch(getUserWagersStart({ userId }))
  try {
    // !TODO : add the use the option object
    var response = await requestService.get(`/wagers/?user=${userId}&${params.toString()}`)
  } catch (error) {
    dispatch(getUserWagersError({ error }))
    return
  }
  const { results, pagination } = await response.json()
  dispatch(getUserWagersSuccess({ results, pagination }))
}

/**
 * Get a single bet information using its id.
 */
export const getUpdatesForMarketBet = marketID => async dispatch => {
  try {
    var response = await requestService.get(`/questions/${marketID}/wagers/`)
  } catch (error) {
    return
  }
  const bets = await response.json()
  bets.results.forEach(bet => dispatch(refreshBet({ bet })))
}

/**
 * Sell a given number of shares for a bet id.
 */
export const sellBet = (betId, shares, amount, marketType, bet_location) => async dispatch => {
  dispatch(sellBetStart({ betId }))
  try {
    var request = await requestService.patch(`/wagers/${betId}/`, { shares, amount, bet_location })
  } catch (error) {
    dispatch(sellBetError({ error, betId }))
    throw error
  }
  const bet = await request.json()
  await dispatch(fetchMarketById(bet.question.id, marketType, true))
  dispatch(sellBetSuccess({ bet }))
  dispatch(fetchCurrentUser(true))
  toastService('wagerSell', bet)

  mixpanelService.sell(bet)
  return bet
}

/**
 * Fetch bet history by question
 */
export const fetchQuestionBetHistory =
  (question, options = {}, clear = false) =>
  async dispatch => {
    const isRealMoney = options.currency_mode === constants.REAL_MONEY
    const params = requestService.parseFilters({ ...options })
    const questionId = question.id

    dispatch(getBetHistoryStart({ isRealMoney, questionId }))
    try {
      var request = await requestService.get(`/questions/${question.id}/actions/?${params.toString()}`)
    } catch (error) {
      dispatch(getBetHistoryError({ isRealMoney, error, questionId }))
      return
    }

    const response = await request.json()
    dispatch(
      getBetHistorySuccess({ isRealMoney, questionId, bets: response.results, pagination: response.pagination, clear })
    )
  }

/**
 * Fetch bet history by user id and market type.
 * Currently only the current user can see their own bets for real money
 */
export const fetchUserBetHistory =
  (user, options = {}) =>
  async dispatch => {
    const isRealMoney = options.currency_mode === constants.REAL_MONEY
    const params = requestService.parseFilters({ ...options })

    dispatch(getUserBetsStart({ isRealMoney, user }))
    try {
      var request = await requestService.get(`/users/${user.id}/actions/?${params.toString()}`)
    } catch (error) {
      dispatch(getUserBetsError({ isRealMoney, error, user }))
      return
    }

    const { results, pagination } = await request.json()
    dispatch(getUserBetsSuccess({ isRealMoney, user, results, pagination }))
  }

export const placeBet =
  (
    outcomeId,
    shares,
    amount,
    amountInUsd,
    currencyId,
    marketType,
    rate_update_code = '',
    fiat_equivalent_mode = false,
    quickbet,
    place = ''
  ) =>
  async dispatch => {
    dispatch(createBetStart())
    try {
      var response = await requestService.post(`/wagers/`, {
        outcome: outcomeId,
        shares: wagerService.roundPrice(shares, 16), // Passing RAW shares to server
        amount: amount,
        fiat_equivalent_mode: Boolean(fiat_equivalent_mode),
        currency: currencyId,
        rate_update_code,
        bet_location: place,
      })
    } catch (err) {
      dispatch(createBetError({ err }))
      throw err.error
    }

    const bet = await response.json()
    const market = await dispatch(fetchMarketById(bet.question.id, marketType, true))
    market && market.discussion_id && subscribeToDiscussion(market.discussion_id)

    dispatch(createBetSuccess({ bet }))
    toastService('wagerBuy', bet, { autoClose: quickbet ? 6000 : 3000 })
    dispatch(fetchCurrentUser(true))
    dispatch(addActiveBetId({ isRealMoney: isCurrencyRealMoney(currencyId), bet }))

    const all_campains = getAllCampains()
    mixpanelService.bet(bet, amountInUsd)
    analyticsService.trackWagerBuy({
      productType: currencyId === currencies.OOM.id ? 'Play Money' : 'Real Money',
      currency: currencyId,
      amount: bet.total_amount,
      amountInUsd,
      all_campains,
    })

    return bet
  }
