import currencies, { getCurrencyMode } from 'common/util/currencies'
import storage from 'common/util/storage'
import Device from 'common/util/Device'
import requestService from 'common/services/request'
import { createSlice } from '@reduxjs/toolkit'
import { urlSearchParams } from 'common/util/urlSearchParams'
import constants from 'app/constants'

const currentStoredCurrency = localStorage.getItem('CapacitorStorage.userCurrency')?.replace(/['"]/g, '')
const initialState = {
  marketType: currentStoredCurrency === currencies.OOM.id ? constants.PLAY_MONEY : constants.REAL_MONEY,
  activeCurrency: currentStoredCurrency ? currentStoredCurrency : currencies.OOM.id,
  tempCurrency: null,
  language: null,
  info: null,
  layout: {
    appLoading: true,
    showNavigationBar: true,
    navbarLogoOnly: false,
    showTabBar: true,
    transparentNavbar: false,
    hideNavbarInfos: false,
    removeNavbar: false,
    displayFooter: true,
    goToWebVersionDisplayed: false,
    fixedCurrencySelector: false,
    currencySelectorOpen: false,
    notificationWidgetOpen: false,
    theme: 'light',
    adminMode: null,
  },
  wagerConstants: { data: {}, status: constants.IDLE },
  exchangeRates: { data: {}, status: constants.IDLE },
  withdrawFees: { data: {}, status: constants.IDLE, error: null },
  abTests: { data: {}, status: constants.IDLE, error: null },
  commentLength: 0,
}

// Reducers / Slices

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setActiveCurrency: (state, action) => {
      const currency = action.payload
      if (typeof currency === 'object' && currency.id) {
        state.activeCurrency = Device.isCordova ? 'OOM' : currency.id
      }

      if (typeof currency === 'string') {
        state.activeCurrency = Device.isCordova ? 'OOM' : currency
      }

      setMarketType(state, { payload: getCurrencyMode(state.activeCurrency) })
    },

    setInfo: (state, action) => {
      state.info = action.payload
    },
    getCommentLength: (state, action) => {
      state.info = action.payload
    },

    parseActiveCurrency: state => {
      var currencyId = state.activeCurrency
      if (currencyId === constants.REAL_MONEY_PLACEHOLDER) {
        currencyId = constants.CANONICAL_CURRENCY
      }
      state.activeCurrency = currencyId
    },

    setMarketType: (state, action) => {
      state.marketType = action.payload
    },

    setTempCurrency: (state, action) => {
      state.tempCurrency = action.payload
    },

    setNavigationBarVisibility: (state, action) => {
      state.layout.showNavigationBar = action.payload
    },
    setAppLoading: (state, action) => {
      state.layout.appLoading = action.payload
    },
    setNavigationBarLogoOnly: (state, action) => {
      state.layout.navbarLogoOnly = action.payload
    },

    setTransparentNavbar: (state, action) => {
      state.layout.transparentNavbar = action.payload
    },

    setTabBarVisibility: (state, action) => {
      state.layout.showTabBar = action.payload
    },

    setRemoveNavbar: (state, action) => {
      state.layout.removeNavbar = action.payload
    },

    setShowNavigationBar: (state, action) => {
      state.layout.showNavigationBar = action.payload
    },

    setNavbarInfosVisibility: (state, action) => {
      state.layout.hideNavbarInfos = action.payload
    },

    setGoToWebVersionDisplayed: (state, action) => {
      state.layout.goToWebVersionDisplayed = action.payload
    },

    setDisplayFooter: (state, action) => {
      state.layout.displayFooter = action.payload
    },

    setFixedCurrencySelector: (state, action) => {
      state.layout.fixedCurrencySelector = action.payload
    },
    setCurrencySelectorOpen: (state, action) => {
      state.layout.currencySelectorOpen = action.payload
    },
    setNotificationWidgetOpen: (state, action) => {
      state.layout.notificationWidgetOpen = action.payload
    },
    setAppTheme: (state, action) => {
      state.layout.theme = action.payload
    },
    setActiveLanguage: (state, action) => {
      state.language = action.payload
    },

    getWagerConstantsStart: state => {
      state.wagerConstants.status = constants.LOADING
    },

    getWagerConstantsSuccess: (state, action) => {
      const { wagerConstants } = action.payload
      state.wagerConstants.data = wagerConstants
      state.wagerConstants.status = constants.COMPLETE
    },

    getExchangeRatesStart: state => {
      state.exchangeRates.status = constants.LOADING
    },

    getExchangeRatesSuccess: (state, action) => {
      const { exchangeRates } = action.payload
      state.exchangeRates.status = constants.COMPLETE
      state.exchangeRates.data = exchangeRates
    },

    getExchangeRatesError: (state, action) => {
      const { error } = action.payload
      state.exchangeRates.status = constants.ERROR
      state.exchangeRates.error = error
    },

    getWithdrawFeesStart: state => {
      state.withdrawFees.status = constants.LOADING
    },

    getWithdrawFeesSuccess: (state, action) => {
      const { fees } = action.payload
      state.withdrawFees.data = fees
      state.withdrawFees.status = constants.COMPLETE
    },

    getWithdrawFeesError: (state, action) => {
      const { error } = action.payload
      state.withdrawFees.error = error
      state.withdrawFees.status = constants.ERROR
    },
    getAbTestsStart: state => {
      state.abTests.status = constants.LOADING
    },
    getAbTestsSuccess: (state, action) => {
      const { abTests } = action.payload
      state.abTests.data = abTests
      state.abTests.status = constants.COMPLETE
    },
    getAbTestsError: (state, action) => {
      const { error } = action.payload
      state.abTests.error = error
      state.abTests.status = constants.ERROR
    },
    getAdminMode: (state, action) => {
      state.layout.adminMode = action.payload
    },
  },
})

export const {
  setActiveCurrency,
  setMarketType,
  setNavigationBarVisibility,
  setAppLoading,
  setNavigationBarLogoOnly,
  setTransparentNavbar,
  setTabBarVisibility,
  setNavbarInfosVisibility,
  setActiveLanguage,
  setTempCurrency,
  getAdminMode,
  setRemoveNavbar,
  getWagerConstantsStart,
  setFixedCurrencySelector,
  setCurrencySelectorOpen,
  setNotificationWidgetOpen,
  setAppTheme,
  getWagerConstantsSuccess,
  getExchangeRatesStart,
  setShowNavigationBar,
  getExchangeRatesSuccess,
  getExchangeRatesError,
  parseActiveCurrency,
  setDisplayFooter,
  getWithdrawFeesSuccess,
  setInfo,
  getCommentLength,
  getWithdrawFeesError,
  getWithdrawFeesStart,
  setGoToWebVersionDisplayed,
  getAbTestsStart,
  getAbTestsSuccess,
  getAbTestsError,
} = app.actions

export default app.reducer

// Selects

export const selectActiveMarketType = state => state.app.marketType
export const selectActiveCurrency = state => state.app.activeCurrency
export const selectPageLayout = state => state.app.layout
export const selectInfo = state => state.app.info
export const selectCommentLength = state => state.app.commentLength
export const selectAbTestsStatus = state => state.app.abTests.status 

export const selectAllExchangeRate = () => state => state.app.exchangeRates.data
export const selectExchangeRate = currencyId => state => {
  if (currencyId === currencies.OOM.id) {
    return 1
  }

  if (!state.app.exchangeRates.data) {
    return null
  }

  return (
    state.app.exchangeRates.data &&
    state.app.exchangeRates.data.BTC &&
    state.app.exchangeRates.data.BTC.rates &&
    state.app.exchangeRates.data.BTC.rates[currencyId]
  )
}

export const selectExchangeRateStatus = () => state => state.app.exchangeRates.status
export const selectExchangeRateUpdateCode = () => state => {
  if (!state.app.exchangeRates.data) {
    return null
  }
  return (
    state.app.exchangeRates.data && state.app.exchangeRates.data.BTC && state.app.exchangeRates.data.BTC.update_code
  )
}

export const selectTempCurrency = () => state => state.app.tempCurrency || state.app.activeCurrency

export const selectActiveLanguage = locale => state => {
  const normalizeLanguage = language => {
    let l = language.toLowerCase().replace(new RegExp('_', 'g'), '-')
    if (l.indexOf('en') > -1) l = 'en'
    return l
  }

  if (locale) {
    return normalizeLanguage(locale)
  }

  if (state.app && state.app.language) {
    return normalizeLanguage(state.app.language)
  }

  if (!Device.isCordova && window && window.location) {
    const urlParams = new urlSearchParams(window.location.search)
    if (urlParams.get('lg')) {
      return normalizeLanguage(urlParams.get('lg'))
    }
  }

  if (global.navigator.languages && global.navigator.languages.length > 0 && global.navigator.languages[0]) {
    return normalizeLanguage(global.navigator.languages[0].toLowerCase())
  }

  if (global.navigator.language) {
    return normalizeLanguage(global.navigator.language.toLowerCase())
  }

  return 'en'
}

export const selectWagerConstants = state => state.app.wagerConstants.data
export const selectWagerConstantsStatus = state => state.app.wagerConstants.status
export const selectWithdrawFees = () => state => state.app.withdrawFees.data
export const selectWithdrawFeesStatus = () => state => state.app.withdrawFees.status
export const selectWithdrawFeesError = () => state => state.app.withdrawFees.error

// Thunks

export const storeActiveCurrency = currency => async dispatch => {
  dispatch(setActiveCurrency(currency))
  storage('userCurrency').set(currency)
}

/**
 * Wager constants contains min price for a specific currency, number of decimal places and other
 * relevant info.
 */
export const fetchWagerConstants = () => async dispatch => {
  dispatch(getWagerConstantsStart())
  const response = await requestService.get(`/wagers/get_wager_constants/`)
  const wagerConstants = await response.json()
  dispatch(getWagerConstantsSuccess({ wagerConstants }))
}

/**
 * Exchange rates contains all information to convert between currencies.
 * Ex: 1 BTC = 16.29743985 ETH
 */
export const fetchExchangeRates = () => async dispatch => {
  try {
    dispatch(getExchangeRatesStart())
    const response = await requestService.get(`/exchange_rates/latest/`)
    const exchangeRates = await response.json()
    dispatch(getExchangeRatesSuccess({ exchangeRates }))
  } catch (err) {
    dispatch(getExchangeRatesError(err))
  }
}

export const fetchWithdrawFees = () => async dispatch => {
  try {
    dispatch(getWithdrawFeesStart())
    const response = await requestService.get(`/finance/withdrawal_fees/`)
    const fees = await response.json()
    dispatch(getWithdrawFeesSuccess({ fees }))
  } catch (e) {
    dispatch(getWithdrawFeesError(e))
  }
}

export const fetchInfo = () => async dispatch => {
  try {
    const response = await requestService.get(`/info/`)
    const info = await response.json()
    dispatch(setInfo({ info }))
  } catch (e) {
    console.error('Info not found')
  }
}
export const fetchABTests = () => async dispatch => {
  try {
    dispatch(getAbTestsStart())
    const response = await requestService.get(`/ab_tests/`)
    const { results } = await response.json()
    dispatch(getAbTestsSuccess(results))
    return results
  } catch (e) {
    console.error('Info not found')
    dispatch(getAbTestsError(e))
  }
}
