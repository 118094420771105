/* @flow */

import requestService from 'common/services/request'
import Sentry from 'app/sentry/Sentry'
import { lmsrPrice, lmsrShares, lslmsrPrice, lslmsrShares } from 'api/price'
import { Decimal } from 'decimal.js'
import currencies from 'common/util/currencies'

// function analyticsData(wagerResponse, shares) {
//   const outcome = !isFinite(wagerResponse.outcome)
//     ? wagerResponse.outcome
//     : wagerResponse.question.outcomes.find(outcome => {
//         return outcome.id === wagerResponse.outcome
//       })

//   const outcomeName = 'title' in outcome ? outcome.title : ''
//   const currency = wagerResponse.currency

//   return {
//     productType: currency === 'OOM' ? 'Play Money' : 'Real Money',
//     currency,
//     outcome: outcome.id,
//     outcome_name: outcomeName,
//     question: wagerResponse.question.id,
//     question_name: wagerResponse.question.title,
//     scoring_rule: wagerResponse.question.scoring_rule,
//     wager: wagerResponse.id,
//     transaction_value: wagerResponse.total_amount,
//     shares,
//   }
// }
class WagerService {
  retrieve(id) {
    return new Promise((resolve, reject) => {
      requestService.get(`/wagers/${id}/`).then(response => {
        if (response.status !== 200) {
          return reject(new Error(`server error${response.status}`))
        }
        return resolve(response.json())
      })
    })
  }

  getPrice(question, outcome, shares, currencyId, exchangeRate = 1) {
    var price
    switch (question.scoring_rule) {
      case 'basic':
        price = outcome.price[currencyId] * shares
        break
      case 'lmsr':
        price = lmsrPrice(question, outcome, shares, currencyId)
        break
      case 'lslmsr':
        price = lslmsrPrice(question, outcome, shares, currencyId)
        break
      default:
        Sentry.log(new Error('Invalid question scoring_rule'), question)
    }

    return price * exchangeRate
  }

  affordableShares(question, outcome, balance, currencyId, minShares, maxShares, exchangeRate) {
    const parsedCurrencyId = currencyId === currencies.OOM.id ? currencies.OOM.id : currencies.CANONICAL.id
    const outcomePrice = outcome.price[parsedCurrencyId] * exchangeRate

    if (balance < outcomePrice * minShares || outcomePrice == 0) {
      return 0
    }

    switch (question.scoring_rule) {
      case 'basic':
        return balance / outcomePrice
      case 'lmsr':
        return lmsrShares(question, outcome, balance, currencyId)
      case 'lslmsr':
        return lslmsrShares(question, outcome, balance, currencyId, minShares, maxShares)
      default:
        Sentry.log(new Error('Invalid question scoring_rule'), question)
    }
  }

  getShares(question, outcome, amount, currencyId, minShares, maxShares) {
    switch (question.scoring_rule) {
      case 'basic':
        return amount / outcome.price[currencyId]
      case 'lmsr':
        return lmsrShares(question, outcome, amount, currencyId)
      case 'lslmsr':
        return lslmsrShares(question, outcome, amount, currencyId, minShares, maxShares)
      default:
        Sentry.log(new Error('Invalid question scoring_rule'), question)
    }
  }

  roundPrice(price, decimalPlaces) {
    const number = new Decimal(price || 0)
    return number.toDecimalPlaces(decimalPlaces).toNumber()
  }

  ceilPrice(price, decimalPlaces) {
    const number = new Decimal(price || 0)
    return number.toDecimalPlaces(decimalPlaces, Decimal.ROUND_UP).toNumber()
  }

  floorPrice(price, decimalPlaces) {
    const number = new Decimal(price || 0)
    return number.toDecimalPlaces(decimalPlaces, Decimal.ROUND_DOWN).toNumber()
  }
}

const instance = new WagerService()
export default instance
