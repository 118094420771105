import React, { useEffect, useState } from 'react'
import { i18n } from 'common/locale/i18n'
import ActiveMarkets from 'explore/components/active-markets'
import FilterDropdownPanel from 'common/components/filter-dropdown-panel'
import InfiniteGrid from 'common/components/InfiniteGrid'
import constants from 'app/constants'
// import Market from 'questions/components/market'

import './MarketListTemplate.scss'
import { ToggleControl } from 'common/components/form-controls'
import { useNavigate } from 'react-router-dom'
import MarketCard from '../market-card/MarketCard'
import classNames from 'classnames'

const MarketListTemplate = ({
  activeMarketType,
  queryParams,
  markets,
  pagination,
  status,
  error,
  onLoadMore,
  setMarketSelected = () => {},
  setOutcomeSelected = () => {},
  marketSelected,
  OutcomeSelected,
}) => {
  const navigate = useNavigate()

  const [isLoadingFirstTime, setIsLoadingFirstTime] = useState(true)

  const isLoading = status === constants.LOADING || status === constants.IDLE

  useEffect(() => {
    if (status === constants.COMPLETE) {
      setIsLoadingFirstTime(false)
    }
  }, [status])

  useEffect(() => {
    if (!isLoading) {
      window.prerenderReady = true
    }
  }, [isLoading])
  const toggleHideMyBetsFilter = () => {
    const params = { ...queryParams }
    if (params.hide_my_bets) delete params.hide_my_bets
    else params.hide_my_bets = 1
    navigate(location.pathname + '?' + new URLSearchParams(params).toString())
  }
  const onRender = ({ index }) => {
    const market = markets[index]
    if (!market) return

    const classList = classNames('question-card', 'question-card--show-bet-status', {
      'question-card--is-selected': market && marketSelected && marketSelected.id === market.id,
    })

    return (
      <article className={classList}>
        <MarketCard
          key={'question-card-' + market.id}
          question={market}
          categories={market && market.category}
          inlineBehavior
          showBetStatus
          showSelectedOutcome
          isSelectedToBet={market && marketSelected && marketSelected.id === market.id}
          currentOutcomeSelection={OutcomeSelected}
          onOutcomeSelected={outcome => {
            setOutcomeSelected(outcome)
            setMarketSelected(market)
          }}
        />
      </article>
    )
  }
  const onRenderSegments = ({ index }) => {
    const heights = [
      [4, 2],
      [2, 5],
      [3, 1],
    ]
    return (
      <>
        {heights[index].map(height => (
          <div className="market-page__card-skeleton " key={'skeleton' + height}>
            <div className="market-page__card-skeleton--header skeleton"></div>

            <div className="market-page__card-skeleton--outcomes ">
              {[...Array(height).keys()].map(_ => (
                <div key={_} className="market-page__card-skeleton--outcome skeleton"></div>
              ))}
            </div>
            <div className="market-page__card-skeleton--footer ">
              <div className="market-page__card-skeleton--footer--details skeleton"></div>
              <div className="market-page__card-skeleton--footer--details skeleton"></div>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <React.Fragment>
      <div className="market-page__active-markets container-wide">
        <ActiveMarkets
          isLoading={isLoading}
          numberOfActiveBets={!isLoading && pagination.total}
          isRealMoney={activeMarketType === constants.REAL_MONEY}
          filters={
            !isLoadingFirstTime && (
              <div className="flex justify-between gap-2 w-full items-center">
                <FilterDropdownPanel className="market-page__filters row" queryParams={queryParams} page="feed" />

                <div className="flex gap-2 items-center">
                  <ToggleControl active={queryParams.hide_my_bets} onToggle={toggleHideMyBetsFilter} />
                  <span>{i18n('filters.hide_my_bets')}</span>
                </div>
              </div>
            )
          }
        />
      </div>

      <InfiniteGrid
        className="market-page__wrapper"
        columnClassName="market-page__column"
        error={error}
        isLoading={isLoading}
        loadedRows={isLoading && markets.length === 0 ? 3 : markets.length}
        onLoadMore={options => onLoadMore(activeMarketType, options)}
        onRowRender={isLoading && markets.length === 0 ? onRenderSegments : onRender}
        placeholder={
          Object.keys(queryParams).length > 0 ? i18n('markets.no_markets_clear_filters') : i18n('markets.no_markets')
        }
        totalRows={pagination ? pagination.total : 0}
        useMasonry={true}
        autoLoading={false}
        breakpoints={{ default: 3, 1200: 2, 768: 1 }}
      />
    </React.Fragment>
  )
}

export default MarketListTemplate
