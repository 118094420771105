import React from 'react'
import BannerGridEntry from 'questions/components/banner-grid-entry'
import BannerGridIntro from 'questions/components/banner-grid-intro'
import './BannerGrid.scss'
import Slider from 'common/ui/slider'
import Steper from 'common/ui/steps'
import { useState } from 'react'
import { LeftArrowIcon, RightArrowIcon } from 'common/components/icons'
import { useEffect } from 'react'
import { matchPath } from 'react-router-dom'
import { useCurrentUser } from 'common/hooks'
import classNames from 'classnames'
import Device from 'common/util/Device'
import initialBanner from 'landing/assets/initialBanner.jpg'

const BannerGrid = ({
  text = '',
  showDescription = true,
  banners = [],
  isSubCategory = false,
  show_swipers = true,
  showIntroBanner = true,
}) => {
  const isInFeed = () => matchPath({ path: '/' }, location.pathname)
  const currentUser = useCurrentUser()
  const [appLoaded, setAppLoaded] = useState(false)
  const getIntroImage = () => {
    const banner = banners.find(e => !e.title) || banners[0] // the intro banner is the banner without the title

    if (banner) return innerWidth >= 768 ? banner.desktop_thumbnail_webp : banner.mobile_thumbnail_webp
    else
      return innerWidth >= 768
        ? initialBanner
        : 'https://futuur-staging-media-nonprod.s3.amazonaws.com/cache/16/65/1665cfb5f05ed33fda71fd1ab0a9969f.webp'
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setAppLoaded(true)
    }, 2000) // Adjust the time as needed

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer)
  }, [])

  const getSliderCluster = () => {
    const highlightedSlide = (
      <BannerGridIntro key={`banner-grid-intro`} text={text} image={getIntroImage()} isConnected={currentUser} />
    )

    const cluster = banners
      .filter(e => e.title)
      .map(item => (
        <BannerGridEntry
          key={`banner-grid-entry-${item.id}`}
          showDescription={showDescription}
          banner={item}
          isSubCategory={isSubCategory}
        />
      ))

    return highlightedSlide !== null && !isSubCategory && showIntroBanner ? [highlightedSlide, ...cluster] : cluster
  }

  const sliderCluster = getSliderCluster()

  let total = sliderCluster.length
  const [current, setCurrent] = useState(0)
  const [userAction, setUserAction] = useState(0)
  const [theLoop, setTheLoop] = useState(0)
  const [my_swiper, set_my_swiper] = useState({})
  const sliderSettings = {
    slidesPerView: 1,
    watchOverflow: true,
    isActive: false,
    centeredSlides: true,
    spaceBetween: Device.isMobileSize ? 16 : 50,
    onSlideChange: slide => setCurrent(slide.activeIndex),
    onInit: ev => {
      set_my_swiper(ev)
    },
  }
  const listSlideRendered = () => {
    // If app is not loaded yet, render only the first slide
    if (!appLoaded && sliderCluster && sliderCluster.length > 0) {
      return [
        <div className="banner-grid__column" key={`banners-grid-0`}>
          {sliderCluster[0]}
        </div>,
      ]
    }

    // If app is fully loaded, render all slides
    return sliderCluster.map((banners, idx) => (
      <div className="banner-grid__column" key={`banners-grid-${idx}`}>
        {banners}
      </div>
    ))
  }
  const swipe = (direction, _userAction = false) => {
    try {
      if (direction === 'left') {
        my_swiper.slidePrev()
      } else {
        my_swiper.slideNext()
      }
      if (_userAction) {
        setUserAction(e => e + 1)
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  useEffect(() => {
    clearInterval(theLoop)
    if (!isInFeed()) return
    let i = 0
    const interval = setInterval(() => {
      if (!my_swiper.slidePrev) return
      i++
      if (i === sliderCluster.length) {
        i = 0
        sliderCluster.forEach(() => {
          swipe('left')
        })
      } else {
        swipe('right')
      }
    }, 300000)
    setTheLoop(interval)

    return () => {
      clearInterval(theLoop)
    }
  }, [my_swiper, userAction, location.pathname])

  return (
    <section className="banner-grid__container">
      <div
        className={classNames('banner-grid', {
          'container-wide': !Device.isMobileSize,
        })}>
        <div className="row">
          {show_swipers && (
            <>
              <div
                className="banner-grid__slider right  d-none d-md-flex"
                onClick={() => {
                  if (current === sliderCluster.length - 1) {
                    sliderCluster.forEach(() => {
                      swipe('left')
                    })
                  } else {
                    swipe('right', true)
                  }
                }}>
                <RightArrowIcon width="8px" />
              </div>
              <div
                className="banner-grid__slider left  d-none d-md-flex"
                onClick={() => {
                  if (current === 0) {
                    sliderCluster.forEach(() => {
                      swipe('right')
                    })
                  } else {
                    swipe('left', true)
                  }
                }}>
                <LeftArrowIcon width="8px" />
              </div>
            </>
          )}
          {sliderCluster.length > 1 ? (
            <>
              <Slider settings={sliderSettings}>{listSlideRendered()}</Slider>
            </>
          ) : (
            listSlideRendered()
          )}
        </div>
        {sliderCluster && sliderCluster.length > 1 && (
          <div className="banner-grid__pagination  d-none d-md-flex">
            <Steper
              style={Device.isMobileSize ? 'dots' : 'lines'}
              fill="#777777"
              currentFill="#FFFFFF"
              total={total}
              current={current}
              showArrows={false}
              move={step => {
                if (step === 1) my_swiper.slideNext()
                else my_swiper.slidePrev()

                setCurrent(current + step)
              }}
            />
          </div>
        )}
      </div>
    </section>
  )
}

export default BannerGrid
