import React from 'react'
import classnames from 'classnames'
import LoadingIndicator from 'common/components/LoadingIndicator'
import PropTypes from 'prop-types'
import './Button.scss'

function Button({
  children,
  className,
  onClick,
  size = 'small',
  disabled = false,
  loading = false,
  variant = 'primary',
  type = 'solid',
}) {
  const classList = classnames(
    'button-main',
    'button-main--size-' + size,
    'button-main--type-' + type,
    'button-main--variant-' + variant,
    {
      'button-main--loading': loading,
    },
    className
  )

  const onClickWrap = e => {
    if (onClick) {
      onClick(e)
    }
  }

  return (
    <button
      role="button"
      className={classList}
      onClick={onClickWrap}
      onMouseUp={e => e.target.blur()}
      onMouseLeave={e => e.target.blur()}
      disabled={disabled}>
      {loading ? <LoadingIndicator mode="inline" style={{ fill: 'currentColor' }} /> : children}
    </button>
  )
}

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
  type: PropTypes.oneOf(['text-only', 'outlined', 'solid', 'discreet']),
  variant: PropTypes.oneOf(['primary', 'shade', 'white']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

class LoadingButton extends React.Component {
  state = {
    isLoading: false,
    disabled: false,
  }

  onClick() {
    this.setState({ isLoading: true }, () => {
      this.props.onClick()
      this.setState({ isLoading: false, disabled: true })
    })
  }

  render() {
    const children = this.props.children
    const { isLoading, disabled } = this.state

    if (disabled || this.props.disabled) {
      return (
        <button className="button-main" disabled>
          {children}
        </button>
      )
    }

    if (isLoading) {
      return (
        <button className="button-main" disabled>
          <LoadingIndicator mode="inline" />
        </button>
      )
    }
    return (
      <Button role="button" onClick={this.onClick.bind(this)}>
        {children}
      </Button>
    )
  }
}

export { Button as default, LoadingButton }
