
import { AuthenticationError } from 'common/errors'
import { getCredential } from 'api/auth'
import { urlSearchParams } from 'common/util/urlSearchParams'
import settingsService from 'common/services/settings'
import storage from 'common/util/storage' 

class RequestService {
  apiVersion = 'v1.4'

  async get(url) {
    const requestOptions = await this.prepareRequest(url, { method: 'GET' })
    return this.callRequest(requestOptions)
  }

  async post(url, data, options = {}) {
    const request = await this.prepareRequest(url, { method: 'POST', body: data })
    return this.callRequest(request, options.retry)
  }

  async patch(url, data) {
    const request = await this.prepareRequest(url, { method: 'PATCH', body: data })
    return this.callRequest(request)
  }
  async put(url, data) {
    const request = await this.prepareRequest(url, { method: 'PUT', body: data })
    return this.callRequest(request)
  }

  async delete(url) {
    const request = await this.prepareRequest(url, { method: 'DELETE' })
    return this.callRequest(request)
  }

  parseApiVersion(url) {
    // Regex: verifies if string starts with version, ex: v1.1, /v1.1, /v1, etc.
    if (new RegExp(/^\/?v(\d+)(\.\d+)?/).test(url) === false && !url.startsWith('/o/')) {
      return '/' + this.apiVersion + url
    }
    return url
  }

  parseFilters(options) {
    const { startIndex, stopIndex, question, ...filters } = options
    const params = new urlSearchParams(filters)

    if (startIndex !== undefined) {
      params.set('offset', startIndex)
    }

    if (stopIndex !== undefined) {
      params.set('limit', stopIndex - startIndex)
    }

    if (question) {
      if (typeof question === 'number') {
        params.set('question', question)
      } else if (question.id) {
        params.set('question', question.id)
      }
    }

    return params
  }

  get_cookies_array() {
    var cookies = {}

    if (document.cookie && document.cookie != '') {
      var split = document.cookie.split(';')
      for (var i = 0; i < split.length; i++) {
        var name_value = split[i].split('=')
        name_value[0] = name_value[0].replace(/^ /, '')
        cookies[decodeURIComponent(name_value[0])] = decodeURIComponent(name_value[1])
      }
    }

    return cookies
  }

  async getAnalyticsParams() {
    const allABTests = JSON.parse(localStorage.getItem('abTests')) || {}
    const utms = JSON.parse((await storage('utms').get()) || '{}')
    const ga4_cid = this.get_cookies_array()['_ga']
    const gtm_current_attrib = this.get_cookies_array()['gtm_current_attrib']
    const gtm_first_attrib = this.get_cookies_array()['gtm_first_attrib']
    if (Object.keys(allABTests).length === 0) var output = { utm: {} }
    else
      output = {
        ab_test: { ...allABTests },
        utm: {},
        ga4_cid: '',
        gtm_current_attrib: '',
        gtm_first_attrib: '',
      }

    for (const key in utms) {
      output.utm[key.replace('utm_', '')] = utms[key]
    }
    if (Object.keys(output.utm).length === 0) {
      delete output.utm
    }
    if (ga4_cid) output.ga4_cid = ga4_cid
    if (gtm_current_attrib) output.gtm_current_attrib = gtm_current_attrib
    if (gtm_first_attrib) output.gtm_first_attrib = gtm_first_attrib

    return JSON.stringify(output)
  }

  getRequestUrl(url) {
    return process.env.REACT_APP_API_URL + this.parseApiVersion(url)
  }

  async getAuthorizationToken() {
    const credential = await getCredential()
    if (credential == null) {
      throw new AuthenticationError('Anonymous User')
    }

    return `${credential.tokenType} ${credential.accessToken}`
  }

  async callRequest(request) {
    try {
      var response = await global.fetch(request)
    } catch (error) {
      console.log(error)
    }

    if (response && response.status >= 200 && response.status <= 210) {
      // Success response
      return response
    } else if (response) {
      // when status is 3xx, 4xx or 5xx
      throw { status: response.status, error: { ...(await response.json()) } }
    } else {
      console.log(request)
      // if an api fails, we fall back to offline page
      // if (location.pathname !== '/offline') location.replace('/offline')
      throw { status: 500, error: { message: 'Server is not available' } }
    }
  }

  async getCredential() {
    return await getCredential()
  }

  async prepareRequest(url, options = {}) {
    const requestUrl = this.getRequestUrl(url)
    const requestOptions = {
      mode: 'cors',
      cache: 'default',
      ...options,
    }

    // Check if body needs to be serialized
    if (
      options &&
      typeof options.body === 'object' && // Array or Object
      /* eslint-disable no-prototype-builtins */
      !(global.FormData && global.FormData.prototype.isPrototypeOf(options.body)) &&
      !(global.Blob && global.Blob.prototype.isPrototypeOf(options.body))
      /* eslint-enable no-prototype-builtins */
    ) {
      //FIXME: Discover why the request works with magic,
      // and why django rest framework does not accept nested objects
      // serialized with JSON.stringify without serializing the inner object first,
      // and escaping the result string.
      if ('attribution' in options.body) {
        options.body.attribution = JSON.stringify(options.body.attribution)
      }
      requestOptions.body = JSON.stringify(options.body)
      requestOptions.headers = {
        ...options.headers,
        'Content-Type': 'application/json',
      }
    }

    const request = new global.Request(requestUrl, requestOptions)
    request.headers.set('Accept', 'application/json')

    // Inject authorization header
    const credential = await this.getCredential()
    if (credential != null) {
      request.headers.set('Authorization', `${credential.tokenType} ${credential.accessToken}`)
    }

    // Defines page language
    const language = settingsService.getLanguage()
    if (language) {
      request.headers.set('Accept-Language', language)
    }

    request.headers.set('analytics', await this.getAnalyticsParams())
    return request
  }
}

const instance = new RequestService()
export default instance
export { RequestService }
