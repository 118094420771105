import React from 'react'
import './PhonePage.scss'
import Device from 'common/util/Device'
import { ArrowRightTailed } from '../icons'
import { useNavigate } from 'react-router-dom'

const PhonePage = ({ children, title }) => {
  const navigate = useNavigate()

  if (!Device.isMobileSize) return children

  return (
    <div className="phone-page">
      <header className="phone-page-header">
        <ArrowRightTailed
          onClick={() => {
            navigate(-1)
          }}
          style={{ transform: 'rotateY(180deg)' }}
          width="16px"
        />
        <h2>{title}</h2>
      </header>
      <>{children}</>
    </div>
  )
}
export default PhonePage
