import React, { useEffect } from 'react'
import TabScrollView, { Tab } from 'common/components/tab-scroll-view'
import constants from 'app/constants'
import { Link, useNavigate } from 'react-router-dom'
import { i18n } from 'common/locale/i18n'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchNotifications,
  readAllNotifications,
  selectNotifications,
  selectNotificationsPagination,
  selectNotificationStatus,
  notificationTypes,
} from 'store/notifications'

import Image from 'common/components/Image'
import InfiniteGrid from 'common/components/InfiniteGrid'
import NotificationItem from 'notifications/components/notification-item'
import classNames from 'classnames'
import parseISO from 'date-fns/parseISO'
import { Markup } from 'interweave'
import { feedUrl } from 'urls'
import {
  fetchUserFollowing,
  followUser,
  selectUserFollowing,
  selectUserFollowingStatus,
  unfollowUser,
} from 'store/users'
import { formatRelative } from 'date-fns'
import { dateOptions } from 'common/locale/en-US'
import { getRedirectUrl } from 'common/util/pushNotifications'
import { fetchCurrentUser, requestCurrentUserEmailConfirmation } from 'store/account'
import { useCurrentUser, usePageWithoutNavbar } from 'common/hooks'
import defaultImage from 'notifications/assets/default.svg'
import picture from '../../common/assets/picture.svg'
import './ViewActivity.scss'
import { userIsAnonymous } from 'api/auth'
import { useState } from 'react'
import Device from 'common/util/Device'
import { setTabBarVisibility } from 'store/app'
import PhonePage from 'common/components/phone-page'
import { useModal } from 'app/components/modal-provider/ModalProvider'
import EmailNotConfirmed from 'app/components/modal-provider/global-modal/email-not-confirmed'
import { BellIcon } from 'common/components/icons'
import LazyLoad from 'react-lazyload'

// TODO: Remove this component when new notification layout for followers is implemented
function NotificationImageLegacy({ notification }) {
  const src = notification.user_following ? notification.user_following.picture : notification.image
  const placeholder = notification.user_following ? picture : defaultImage

  return (
    <Image
      className={classNames('notification-template__image', {
        'notification-image--round': notification.user_following != null,
      })}
      src={src}
      placeholder={placeholder}
    />
  )
}

// TODO: Remove this component when new notification layout for followers is implemented
function NotificationItemLegacy({ notification }) {
  const targetUrl = getRedirectUrl({ ...notification })
  const time = formatRelative(parseISO(notification.created), new Date(), { addSuffix: true, ...dateOptions })
  return (
    <Link to={targetUrl} className="notification-template">
      <div className="notification-template__container">
        <NotificationImageLegacy notification={notification} />
        <div className="notification-template__content">
          <div className="notification-template__title">
            <Markup className="notification-template__message" tagName="div" content={notification.message} />
          </div>
          <div className="notification-template__date">{time}</div>
        </div>
      </div>
    </Link>
  )
}

const renderSkeletonLoading = () => {
  return [0, 1, 2, 3, 4, 5].map(id => (
    <div key={'notification-template__container-skeleton' + id} className="notification-template__container m-4 ">
      <span className="notification-template__image skeleton"></span>
      <div className="notification-template__content">
        <div>
          <div className="notification-template__title skeleton"></div>
        </div>
        <p className="notification-template__paragraph skeleton"></p>
        <div className="notification-template__date skeleton"></div>
      </div>
    </div>
  ))
}

function NotificationList({ following = false, componentBottomed = false }) {
  const dispatch = useDispatch()
  const type = following ? notificationTypes.following : notificationTypes.mine
  const notificationStatus = useSelector(selectNotificationStatus(type))
  const notifications = useSelector(selectNotifications(type))
  const notificationsPagination = useSelector(selectNotificationsPagination(type))
  const isLoading = notificationStatus === constants.LOADING
  const currentUser = useCurrentUser()
  const followingList = useSelector(selectUserFollowing(currentUser)).map(user => user.id)
  const followingUserStatus = useSelector(selectUserFollowingStatus(currentUser))
  const { showModal } = useModal()

  useEffect(() => {
    if (notificationStatus === constants.IDLE) {
      loadMore({})
    }
  }, [following])
  useEffect(() => {
    if (
      componentBottomed &&
      !isLoading &&
      (!notificationsPagination.total || 0 || notifications.length < notificationsPagination.total || 0)
    ) {
      loadMore({ startIndex: notifications.length })
    }
  }, [componentBottomed])

  useEffect(() => {
    if (followingUserStatus === constants.IDLE) {
      dispatch(fetchUserFollowing(currentUser, {}))
    }
  }, [])

  const loadMore = options => {
    dispatch(fetchNotifications({ following_notifications: following ? 1 : 0, ...options }))
  }

  const renderListItem = ({ index }) => {
    const notification = notifications[index]
    // TODO: Followers notifications must implement new layout
    if (notification.user_following) {
      return (
        <LazyLoad key={`notification-item-${notification.id}`} overflow  height={107}>
          <NotificationItemLegacy key={`notification-item-following-${notification.id}`} notification={notification} />
        </LazyLoad>
      )
    }
    return (
      <LazyLoad key={`notification-item-${notification.id}`} overflow  height={107}>
        <NotificationItem
          key={`notification-item-${notification.id}`}
          notification={notification}
          userFollowingIds={followingList}
          onFollow={onFollowUser}
          onUnfollow={onUnfollowUser}
          onResendEmail={onResendEmail}
        />
      </LazyLoad>
    )
  }

  const onFollowUser = user => {
    if (currentUser && !currentUser.email_confirmed) {
      showModal({
        title: ' ',
        showCloseButton: true,
        content: <EmailNotConfirmed />,
      })
      return
    }
    dispatch(followUser(currentUser.id, user.id))
  }

  const onUnfollowUser = user => {
    dispatch(unfollowUser(currentUser.id, user.id))
  }

  const onResendEmail = () => {
    dispatch(requestCurrentUserEmailConfirmation())
  }

  if (isLoading && notifications.length === 0) return renderSkeletonLoading()

  if (notifications.length === 0) {
    return (
      <div className="notification-list notification-list--empty">
        <i>
          <BellIcon width={20} />
        </i>
        {i18n('activity.empty_list')}
      </div>
    )
  }

  return (
    <div className="notification-list">
      <section className="notification-list__section notification-list__section--unread">
        <InfiniteGrid
          className="view-feed__wrapper"
          columnClassName="view-feed__column"
          isLoading={isLoading}
          loadedRows={notifications.length}
          onLoadMore={loadMore}
          onRowRender={renderListItem}
          useLazyLoad
          totalRows={notificationsPagination.total || 0}
          autoLoading={true}
        />
      </section>
    </div>
  )
}

function ViewActivity() {
  usePageWithoutNavbar()
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const [componentBottomed, setComponentBottomed] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(setTabBarVisibility(false))
    return () => {
      dispatch(setTabBarVisibility(true))
    }
  })
  useEffect(() => {
    const asyncFn = async () => {
      if (!(await userIsAnonymous()) && (!currentUser || !currentUser.id)) {
        dispatch(fetchCurrentUser())
      } else if (!currentUser || !currentUser.id) {
        navigate(feedUrl())
      } else {
        dispatch(readAllNotifications())
      }
    }
    asyncFn()
  }, [])

  if (!currentUser || !currentUser.id) {
    return renderSkeletonLoading()
  }

  return (
    <PhonePage title="Notification">
      <TabScrollView
        name="view-activity"
        initialActive="user-activity"
        onScroll={e => {
          if (!Device.isMobileSize) {
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
            if (bottom !== componentBottomed) {
              setComponentBottomed(bottom)
            }
          }
        }}>
        <Tab label={i18n('activity.tabs.user')} tabKey="user-activity">
          <NotificationList componentBottomed={componentBottomed} />
        </Tab>
        <Tab label={i18n('activity.tabs.following')} tabKey="following-activity">
          <NotificationList following componentBottomed={componentBottomed} />
        </Tab>
      </TabScrollView>
    </PhonePage>
  )
}

export { ViewActivity }
