import React from 'react'

import AppUpdateRequest from 'common/components/app-update-request'
import { i18n } from 'common/locale/i18n'
import ActionPopup from 'common/components/action-popup'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserBets } from 'store/wagers'
import { useRef } from 'react'
import {
  useCurrencies,
  useCurrentUser,
  useGeneralInfo,
  usePWAInstallation,
  useWebPushNotification,
  userRealTimeDepositStatus,
} from 'common/hooks'
import { useState } from 'react'
import { selectActiveMarketType, storeActiveCurrency } from 'store/app'
import Device from 'common/util/Device'
import Modal from 'common/components/modal/Modal'
import ActionSheetLayout from 'layouts/components/action-sheet-layout'
import { useModal } from '../modal-provider/ModalProvider'
import DepositSuccess from '../modal-provider/global-modal/deposit-success'
import { toastService } from 'api/toast'

const GlobalComponent = () => {
  const pwaInstallation = usePWAInstallation()
  const currentUser = useCurrentUser()
  const { modalProps, hideModal } = useModal()
  const [tempDecline, setTempDecline] = useState(false)
  const [displayShouldUpdate, setDisplayShouldUpdate] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const activeMarketType = useSelector(selectActiveMarketType)
  const activeBets = useSelector(selectUserBets(activeMarketType, true))
  const pastBets = useSelector(selectUserBets(activeMarketType, false))
  const webPushNotification = useWebPushNotification()
  const firstRenderRef = useRef(true)
  const generalInfo = useGeneralInfo()
  const { showModal } = useModal()
  const currencies = useCurrencies().currencies
  const dispatch = useDispatch()
  userRealTimeDepositStatus({
    onReceiveMessage: data => {
      showModal({
        title: (
          <div className="view-crypto-cash-in__header">
            <div>{i18n('cash_in.add_funds')}</div>
          </div>
        ),
        showCloseButton: true,
        content: <DepositSuccess data={data} />,
        hideModal: hideModal,
      })
      toastService('deposit', {
        amount: data.amount,
        currency: currencies[data.currency],
      })
      dispatch(storeActiveCurrency(data.currency))
    },
  })
  //   refresh cache.
  useEffect(() => {
    // Awaiting the execution of generalInfo.refreshCache.
    generalInfo.refreshCache()
  }, [generalInfo])
  useEffect(() => {
    // Awaiting the execution of showUpdateRequest.
    showUpdateRequest()
  }, [generalInfo])
  const onAcceptNotification = () => {
    setTempDecline(true)
    webPushNotification.pushSubscription()
    webPushNotification.onMessageListener()
  }
  useEffect(() => {
    if (currentUser && webPushNotification.isNotificationGranted() && firstRenderRef.current) {
      webPushNotification.pushSubscription()
      firstRenderRef.current = false
    }
  }, [currentUser])
  const showUpdateRequest = async () => {
    if (!Device.isIOS && !Device.isAndroid) {
      setDisplayShouldUpdate(false)
    } else {
      try {
        const minimalAppRequested = (generalInfo && generalInfo.info && generalInfo.info.APP_LAST_UNSAFE_VERSION) || '0'
        const currentAppVersion = (await generalInfo.getCurrentAppVersion()) || '211111'

        if (minimalAppRequested === 'NOT_SET' || minimalAppRequested === '' || !currentAppVersion) {
          setDisplayShouldUpdate(false)
          return false
        }
        setDisplayShouldUpdate(Number(minimalAppRequested) > Number(currentAppVersion))
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    // asking for webnotification
    const thereIsBets = (activeBets && activeBets.length > 0) || (pastBets && pastBets.length > 0)

    const shouldDisplayNotification = webPushNotification.shouldAskForNotification() && thereIsBets
    setShowNotification(shouldDisplayNotification)
  }, [activeBets, pastBets])

  const declineNotification = () => {
    setTempDecline(true)
  }

  // PWA INSTALLATION
  const onInstallPWA = () => {
    pwaInstallation.install()
  }

  const onDismissPWAInstallation = async () => {
    setTimeout(() => {
      pwaInstallation.dismiss()
    }, 1000)
  }
  const renderContent = () => {
    if (typeof modalProps.content === 'function') {
      return modalProps.content()
    }
    return modalProps.content
  }
  return (
    <>
      {pwaInstallation.shouldPromptInstallation && currentUser !== null && (
        <ActionPopup
          titleText={i18n('pwa_installation.title')}
          bodyText={i18n('pwa_installation.subtitle')}
          buttonText={i18n('pwa_installation.actionButton')}
          onDismiss={onDismissPWAInstallation}
          onAccept={onInstallPWA}
          position="mobile-bottom desktop-top"
        />
      )}

      {currentUser !== null && !tempDecline && showNotification && (
        <ActionPopup
          titleText={i18n('ask_notification_permission.title')}
          bodyText={i18n('ask_notification_permission.subtitle')}
          buttonText={i18n('ask_notification_permission.actionButton')}
          onDismiss={declineNotification}
          position="mobile-bottom desktop-top"
          onAccept={onAcceptNotification}
        />
      )}
      {displayShouldUpdate && <AppUpdateRequest />}
      <Modal isOpen={modalProps && modalProps.isOpen} position="full" onCloseRequested={hideModal}>
        <ActionSheetLayout {...modalProps} title={modalProps.title} content={renderContent()} onClose={hideModal} />
      </Modal>
    </>
  )
}

export default GlobalComponent
