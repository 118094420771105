import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { getRedirectUrl } from 'common/util/pushNotifications'
import { format, addMinutes } from 'date-fns'
import './NotificationTemplate.scss'
import { useDispatch } from 'react-redux'
import { setNotificationWidgetOpen } from 'store/app'
import classNames from 'classnames'

const NotificationTemplate = ({ type, notification, imageOverlay, notificationData, ...props }) => {
  const title = notificationData?.title || ''
  const content = notificationData?.paragraph || ''
  const date = new Date(notification.created)
  const dispatch = useDispatch()
  const icon = notificationData.icon
  const utcFormattedDate = format(addMinutes(date, date.getTimezoneOffset()), "MMMM dd, y • HH':'mm 'UTC'")
  const link = getRedirectUrl({ ...notification })
  const image =
    notificationData?.image ||
    props.image ||
    (notification.user_following ? notification.user_following.picture : notification.image)
  const notRead = notification && !notification.is_read
  const getLinkContent = () => {
    return (
      <div className="notification-template__container">
        <span className="notification-template__image">
          {notRead && (
            <svg
              className="notification-template__image--notRead"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none">
              <circle cx="6" cy="6" r="6" fill="#ED157D" />
            </svg>
          )}
          {icon ? (
            <div className="notification-template__icon">{icon}</div>
          ) : (
            <img src={image} className="notification-template__image" alt={title} title={title} />
          )}
          <span className="notification-template__image__overlay">{imageOverlay}</span>
        </span>

        <div className="notification-template__content">
          <div>
            {/* <div className="notification-template__content__firstRow">
              {(type === 'WON' || type === 'LOST') && (
                <div className="notification-template__content__firstRow--status">
                  <BetStatus backGroundFill={getBetStatusColor().background} dotFill={getBetStatusColor().dot} />
                  <span>{type === 'WON' ? 'Win!' : type === 'LOST' ? 'Loss!' : ''}</span>
                </div>
              )}
             
            </div> */}
            {/* {label && <div className="notification-template__label">{label}</div>}  */}

            {title && <div className="notification-template__title">{title}</div>}
          </div>

          {content && <p className="notification-template__paragraph">{content}</p>}
          {utcFormattedDate && type === 'FOLLOW' && (
            <time className="notification-template__date">{utcFormattedDate}</time>
          )}
          {utcFormattedDate && type !== 'FOLLOW' && (
            <time className="notification-template__date">{utcFormattedDate}</time>
          )}
        </div>
      </div>
    )
  }
  return link.isExternel ? (
    <a
      {...props}
      href={link.link}
      target="_blank"
      className="notification-template hover:bg-[var(--color-Neutral-2)]"
      rel="noreferrer">
      {getLinkContent()}
    </a>
  ) : (
    <Link
      {...props}
      to={link}
      onClick={() => {
        dispatch(setNotificationWidgetOpen(false))
      }}
      className={classNames('notification-template  hover:bg-[var(--color-Neutral-2)]', {
        
        'notification-template--unread': notRead,
      })}
      rel="canonical">
      {getLinkContent()}
    </Link>
  )
}

NotificationTemplate.propTypes = {
  imageOverlay: PropTypes.object,
  datePrefix: PropTypes.string,
  title: PropTypes.any,
  content: PropTypes.any,
  label: PropTypes.string,
}

export default NotificationTemplate
