import React, { useEffect, useState } from 'react'
import FilterDropdown from 'common/components/filter-dropdown/FilterDropdown'
import { i18n } from 'common/locale/i18n'
import { useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import './FilterDropdownPanel.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectRootCategoryList } from 'store/categories'
import { selectActiveCurrency, selectActiveMarketType, setMarketType, storeActiveCurrency } from 'store/app'
import constants from 'app/constants'
import { useCurrencies } from 'common/hooks'
import CurrencyIcon from '../currencyIcon'
import Device from 'common/util/Device'
import Modal from 'common/components/modal/Modal'
import ActionSheetLayout from 'layouts/components/action-sheet-layout'
import { DotIcon, FiltersIcon } from '../icons'
import Button from 'common/ui/button'

const FilterDropdownPanel = ({
  className = '',
  containerClass = '',
  queryParams = {},
  onChange,
  filterOf = 'all',
  page = 'feed',
  setOnChangeFilters = [],
  statusList = [],
  currencies,
  ...props
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const activeMarketType = useSelector(selectActiveMarketType)
  const _currencies = useCurrencies().currencies
  const categories = useSelector(selectRootCategoryList(activeMarketType))
  const [activeCurrencyMode, setLocalActiveCurrencyMode] = useState(
    useSelector(selectActiveCurrency) == 'OOM' ? constants.PLAY_MONEY : constants.REAL_MONEY
  )
  const [categoriesSelected, setCategoriesSelected] = useState(props.categoriesSelected)
  const [currenciesSelected, setCurrenciesSelected] = useState(props.currenciesSelected)
  const [marketStatusSelected, setMarketStatusSelected] = useState(props.marketStatusSelected)
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => setMarketStatusSelected(props.marketStatusSelected), [props.marketStatusSelected])
  const categorieOptions = categories
    .filter(cat => cat !== undefined)
    .map(cat => ({ value: cat.id + '', text: cat.title }))

  if (page !== 'portfolio') categorieOptions.unshift({ value: '', text: i18n('filters.default_category') })
  const currencySelector = [
    {
      value: constants.REAL_MONEY,
      text: i18n('Real_money'),
    },
    {
      value: constants.PLAY_MONEY,
      text: i18n('Play_money'),
    },
  ]

  if (page === 'portfolio') {
    queryParams.currency_mode = activeCurrencyMode === 'OOM' ? constants.PLAY_MONEY : constants.REAL_MONEY
  }

  const changeSearchFilter = (filter, value) => {
    const params = {}
    if (queryParams.ordering) params.ordering = queryParams.ordering
    if (queryParams.currency_mode) params.currency_mode = queryParams.currency_mode
    if (queryParams.currenciesSelected) params.currenciesSelected = queryParams.currenciesSelected
    if (queryParams.resolved_and_sold) params.resolved_and_sold = queryParams.resolved_and_sold
    if (queryParams.market_status) params.market_status = queryParams.market_status
    if (queryParams.live_soon) params.live_soon = queryParams.live_soon
    if (queryParams.live) params.live = queryParams.live
    if (queryParams.resolved_only) params.resolved_only = queryParams.resolved_only
    if (queryParams.hide_my_bets) params.hide_my_bets = queryParams.hide_my_bets
    if (queryParams.people_i_follow) params.people_i_follow = queryParams.people_i_follow
    if (queryParams.category) params.category = queryParams.category
    if (queryParams.type) params.type = queryParams.type
    if (queryParams.q) params.q = queryParams.q

    value ? (params[filter] = value) : delete params[filter]

    if (!Device.isMobileSize && page !== 'portfolio') {
      setOnChangeFilters.forEach(field => {
        field.action(queryParams[field.filter])
      })
    }
    // Inverted filters : one cancels the other
    if (filter === 'live_soon' || filter === 'live' || filter === 'all') {
      delete params.resolved_only
      delete params.resolved_and_sold
    }
    if (filter === 'resolved_only' || filter === 'resolved_and_sold' || filter === 'all') {
      delete params.live_soon
      delete params.live
    }
    const filterTouched = setOnChangeFilters.find(field => field.filter === filter)
    if (!Device.isMobileSize && filterTouched && filterTouched.action) {
      filterTouched.action(params[filter])
    }
    if (page !== 'portfolio') navigate(location.pathname + '?' + new URLSearchParams(params).toString())
    if (onChange && !Device.isMobileSize) onChange()
  }
  const onChangeFilter = (filter, value) => {
    changeSearchFilter(filter, value)
  }
  const changeCurrencyMode = currency_mode => {
    dispatch(storeActiveCurrency(currency_mode === constants.REAL_MONEY ? constants.CANONICAL_CURRENCY : 'OOM'))
    setLocalActiveCurrencyMode(currency_mode)
    queryParams.currency_mode = currency_mode
    dispatch(setMarketType(currency_mode))
  }

  const shouldShowFilter = filter => {
    switch (filter) {
      case 'sort_by':
        return ['all', 'markets'].includes(filterOf)
      case 'currencies':
        return page === 'portfolio'
      case 'currency_mode':
        return false
      case 'default_category':
        return (['all', 'markets'].includes(filterOf) && page === 'search') || page === 'portfolio'
      case 'live_markets':
        return false //['all', 'markets'].includes(filterOf) && page === 'feed'
      case 'live':
        return false //page === 'profile'
      case 'resolved_and_sold':
        return false //page === 'profile'
      case 'market_status':
        return page === 'portfolio'
      case 'resolved_only':
        return ['all', 'markets'].includes(filterOf) && page !== 'portfolio'
      case 'all':
        return ['all', 'markets'].includes(filterOf) && page !== 'portfolio'
      case 'hide_my_bets':
        return ['all', 'markets'].includes(filterOf) && page === 'search'
      case 'people_i_follow':
        return ['users'].includes(filterOf)
      default:
        return true
    }
  }
  const hideModal = () => {
    setModalOpen(false)
  }

  const render = () => {
    return (
      /**
       * TODO : when fixing filter for other pages uncomment this css
       */
      // <ul className={classnames('filter-dropdown-panel', 'flex-col md:flex-row', className)}>
      <ul
        className={classnames('filter-dropdown-panel', className, {
          'flex-col md:flex-row': page === 'portfolio',
          'flex-row': page !== 'portfolio',
        })}>
        {shouldShowFilter('currencies') && (
          <li>
            <FilterDropdown
              type="list"
              multiple
              title={i18n('question_card.currencies')}
              placeholder={i18n('portfolio.all_currencies')}
              value={currenciesSelected}
              list={
                _currencies &&
                currencies
                  .map(c => [c, _currencies[c]])
                  .map(([currencyId, currency]) => ({
                    value: currencyId,
                    text: currencyId === 'OOM' ? i18n('Play_money') : currencyId,
                    icon: <CurrencyIcon currency={currency} width="20px" />,
                  }))
                  .filter(({ value }) => value !== constants.REAL_MONEY_PLACEHOLDER)
              }
              onChange={value => {
                setCurrenciesSelected(value.length > 0 ? value : null)
                onChangeFilter('currencies', value)
              }}
              flat={Device.isMobileSize && page === 'portfolio'}
            />
          </li>
        )}
        {shouldShowFilter('market_status') && (
          <li>
            <FilterDropdown
              type="list"
              multiple
              title={i18n('filters.categories')}
              placeholder="Status"
              value={marketStatusSelected && marketStatusSelected.length > 0 ? marketStatusSelected : null}
              list={statusList}
              onChange={value => {
                setMarketStatusSelected(value.length > 0 ? value : null)
                onChangeFilter('market_status', value)
              }}
              flat={Device.isMobileSize && page === 'portfolio'}
            />
          </li>
        )}
        {shouldShowFilter('currency_mode') && (
          <li>
            <FilterDropdown
              type="list"
              placeholder={i18n('currency_selector.title')}
              value={activeCurrencyMode}
              list={currencySelector}
              onChange={changeCurrencyMode}
              flat={Device.isMobileSize && page === 'portfolio'}
            />
          </li>
        )}
        {shouldShowFilter('default_category') && (
          <li>
            <FilterDropdown
              type="list"
              placeholder={i18n('filters.default_category')}
              title={i18n('filters.categories')}
              value={categoriesSelected || queryParams.category}
              list={categorieOptions}
              multiple={page === 'portfolio'}
              onChange={value => {
                setCategoriesSelected(value.length > 0 ? value : null)
                onChangeFilter('category', value)
              }}
              flat={Device.isMobileSize && page === 'portfolio'}
            />
          </li>
        )}
        {shouldShowFilter('resolved_and_sold') && (
          <li>
            <FilterDropdown
              type="boolean"
              value={queryParams.resolved_and_sold}
              placeholder={i18n('markets.resolved_and_sold')}
              onChange={value => {
                onChangeFilter('resolved_and_sold', value)
              }}
              flat={Device.isMobileSize && page === 'portfolio'}
            />
          </li>
        )}
        {shouldShowFilter('all') && (
          <li>
            <FilterDropdown
              type="boolean"
              value={!queryParams.resolved_only && !queryParams.live}
              placeholder={i18n('markets.all_markets')}
              onChange={value => onChangeFilter('all', value)}
              flat={Device.isMobileSize && page === 'portfolio'}
            />
          </li>
        )}
        {shouldShowFilter('live_markets') && (
          <li>
            <FilterDropdown
              type="boolean"
              placeholder={i18n('markets.live_markets')}
              value={queryParams.live}
              onChange={value => onChangeFilter('live', value)}
              flat={Device.isMobileSize && page === 'portfolio'}
            />
          </li>
        )}
        {shouldShowFilter('live') && (
          <li>
            <FilterDropdown
              type="boolean"
              placeholder={i18n('markets.live_markets')}
              value={queryParams.live}
              onChange={value => onChangeFilter('live', value)}
              flat={Device.isMobileSize && page === 'portfolio'}
            />
          </li>
        )}
        {shouldShowFilter('resolved_only') && (
          <li>
            <FilterDropdown
              type="boolean"
              value={queryParams.resolved_only}
              placeholder={i18n('markets.resolved_only')}
              onChange={value => onChangeFilter('resolved_only', value)}
              flat={Device.isMobileSize && page === 'portfolio'}
            />
          </li>
        )}
        {shouldShowFilter('hide_my_bets') && (
          <li>
            <FilterDropdown
              type="boolean"
              value={queryParams.hide_my_bets}
              placeholder={i18n('filters.hide_my_bets')}
              onChange={value => onChangeFilter('hide_my_bets', value)}
              flat={Device.isMobileSize && page === 'portfolio'}
            />
          </li>
        )}
        {shouldShowFilter('people_i_follow') && (
          <li>
            <FilterDropdown
              type="boolean"
              value={queryParams.people_i_follow}
              placeholder={i18n('filters.people_i_follow')}
              onChange={value => onChangeFilter('people_i_follow', value)}
              flat={Device.isMobileSize && page === 'portfolio'}
            />
          </li>
        )}
      </ul>
    )
  }
  return Device.isMobileSize && page === 'portfolio' ? (
    <>
      <div
        onClick={() => setModalOpen(true)}
        className="rounded-full relative flex w-10 h-10 items-center justify-center bg-[var(--color-Neutral-1)] border border-solid border-[var(--color-Neutral-3)]">
        <FiltersIcon width="13px" fill="var(--color-Neutral-6)" />
        {(currenciesSelected || marketStatusSelected || categoriesSelected) && (
          <i className="absolute right-0 bottom-0">
            <DotIcon color="var(--color-brand-04)" />
          </i>
        )}
      </div>

      <Modal className="h-auto" isOpen={modalOpen} position="bottom" onCloseRequested={hideModal}>
        <ActionSheetLayout
          containerClass={containerClass}
          title="Filter by"
          content={render()}
          onClose={hideModal}
          showCloseButton
          footer={
            <div className="absolute bottom-0 w-full left-0 p-4 bg-[var(--color-Neutral-1)]">
              <Button
                size="full"
                onClick={() => {
                  setOnChangeFilters.find(f => f.filter === 'currencies').action(currenciesSelected)
                  setOnChangeFilters.find(f => f.filter === 'category').action(categoriesSelected)
                  setOnChangeFilters.find(f => f.filter === 'market_status').action(marketStatusSelected)
                  hideModal()
                }}>
                Apply
              </Button>
            </div>
          }
        />
      </Modal>
    </>
  ) : (
    render()
  )
}

export default FilterDropdownPanel
