import React, { lazy } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { realMoneyCurrencies } from 'common/util/currencies'
import Currency from 'common/currency'
import Device from 'common/util/Device'
import './CurrencySelector.scss'
import { i18n } from 'common/locale/i18n'
import constants from 'app/constants'
const CurrencySelectorPanel = lazy(() => import('currencies/components/currency-selector-panel'))
import { useCurrentUser, useCurrencies } from 'common/hooks'
import {
  selectActiveMarketType,
  selectPageLayout,
  setActiveCurrency,
  setCurrencySelectorOpen,
  setMarketType,
  storeActiveCurrency,
} from 'store/app'
import { useState } from 'react'
import { useEffect } from 'react'
// import { resetAllMarkets } from 'store/markets'
import { updateCurrentUserSettings } from 'store/account'
import { Suspense } from 'react'
import SuspensePanel from 'common/components/suspense'
import { Crevron } from 'common/components/icons'
import CurrencyIcon from 'common/components/currencyIcon'

function CurrencySelector({ me, currency, className, hidePrice = false, highlightPrice = false }) {
  const currencies = useCurrencies().currencies
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()
  const marketType = useSelector(selectActiveMarketType)
  const pageLayout = useSelector(selectPageLayout)
  const [initialCurrencyType, setInitialCurrencyType] = useState(marketType)
  const [isOpen, setOpenPanel] = useState(false)
  const classList = classnames(
    'currency-selector',
    currency && 'currency-selector--' + currency.id.toLowerCase(),
    className,
    {
      'currency-selector--isOpen': isOpen,
      'currency-selector--hide-price': hidePrice,
      'currency-selector--highlight-price': highlightPrice,
    }
  )
  const icon = <CurrencyIcon currency={currencies[currency.id]} width="20px" />
  useEffect(() => {
    if (currentUser && currentUser.settings && !initialCurrencyType) {
      const currencyType =
        currentUser.settings.last_currency_selected === 'OOM' ? constants.PLAY_MONEY : constants.REAL_MONEY
      setInitialCurrencyType(currencyType)
    }
  }, [currentUser])
  useEffect(() => {
    setOpenPanel(pageLayout.currencySelectorOpen)
  }, [pageLayout.currencySelectorOpen])
  const setOpenPanelProxy = state => {
    dispatch(setCurrencySelectorOpen(state))
    setOpenPanel(state)
  }
  const onConfirmChanges = currency => {
    dispatch(storeActiveCurrency(currency))

    if (currency === 'OOM') {
      dispatch(setMarketType(constants.PLAY_MONEY))
    } else {
      dispatch(setMarketType(constants.REAL_MONEY))
    }
  }
  const onSelectCurrencyType = type => {
    if (type === constants.PLAY_MONEY) {
      dispatch(updateCurrentUserSettings({ last_currency_selected: 'OOM' }))
      // setLocalActiveCurrency('OOM')
    }

    if (type === constants.REAL_MONEY) {
      dispatch(updateCurrentUserSettings({ last_currency_selected: constants.CANONICAL_CURRENCY }))
      // setLocalActiveCurrency(constants.CANONICAL_CURRENCY)
    }
  }
  const onChangeCurrency = currency => {
    dispatch(setActiveCurrency(currency))
  }
  const defaultCurrencies = Object.values(realMoneyCurrencies).map(currency => currency.id)

  const getBalance = currencyId => {
    const value = me && me.id && me.wallet ? me.wallet[currencyId] : 0
    return <Currency showSuffix={false} value={value} currency={currencyId} />
  }

  const getDisplayCode = currency => {
    const value = me && me.id && me.wallet ? me.wallet[currency.id] : 0
    if (Device.isMobileSize && !value) return ''
    if (currency.id === currencies.OOM.id && !me) {
      return i18n('Play_money')
    }
  }
  const getButtonText = () => {
    if (Device.isCordova) {
      return getBalance(currencies.OOM.id, true)
    }

    const realMoneyText = (
      <span className="currency-selector__real-money">{i18n('currency_selector.real_money_title')}</span>
    )

    if (currency.id === useCurrencies()[constants.REAL_MONEY_PLACEHOLDER].id) {
      return i18n('Real_money')
    }

    if (!me || !me.id) {
      return ''
    }

    if (me.real_currency_enabled) {
      return getBalance(currency.id)
    } else {
      return currency.id !== currencies.OOM.id ? getBalance(currency.id) : realMoneyText
    }
  }

  const selectorContent = (
    <div className="currency-selector__button">
      {icon}

      {getButtonText()}
      <div className="currency-selector__button--cover" onClick={() => setOpenPanelProxy(!isOpen)}></div>
      <span className={classnames('currency-selector__icon', { 'currency-selector__icon--logedoff': !me })}>
        {getDisplayCode(currency)}
      </span>
      {!Device.isCordova && (
        <i
          className={classnames('currency-selector__button--cervron', {
            'currency-selector__button--cervron-open': isOpen,
          })}>
          <Crevron width="8.5px" height="5px" fillcolor="currentColor" />
        </i>
      )}
    </div>
  )
  return (
    <div className={classList}>
      {selectorContent}
      {isOpen && (
        <Suspense fallback={<SuspensePanel />}>
          <CurrencySelectorPanel
            isOpen={isOpen}
            onClose={() => setOpenPanelProxy(false)}
            user={currentUser}
            initialCurrencyType={initialCurrencyType}
            onChangeCurrency={onChangeCurrency}
            onConfirmChanges={onConfirmChanges}
            onSelectCurrencyType={onSelectCurrencyType}
            isMobileApp={Device.isCordova}
            currencies={defaultCurrencies}
          />
        </Suspense>
      )}
    </div>
  )
}

export { CurrencySelector }
