import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import './FilterDropdown.scss'
import { ApprovedIcon, Crevron } from '../icons' // Corrected typo 'Crevron' to 'Chevron'
import { useRef } from 'react'
import { CheckControl } from '../form-controls'

// The FilterDropdown component allows users to select a value from a dropdown list.
const FilterDropdown = ({
  placeholder = '', // Placeholder text displayed when no value is selected.
  keepPlaceholder = false, // Determines if placeholder should remain visible after selection.
  value = '', // Currently selected value.
  type = 'boolean', // Determines the type of dropdown, affecting how selections are handled.
  title = '', // Title text displayed above the dropdown.
  list = [], // List of selectable options.
  onChange, // Callback function called when the selection changes.
  className, // Custom class names to apply for styling purposes.
  arrow = 'outlined', // Determines the arrow style in the dropdown.
  disabled = false, // Disables interaction if true.
  highlightSelected = false, // Highlights the selected item if true.
  onlyIcon = false, // If true, only the icon of the selected item is shown.
  onlyText = false, // If true, only the text of the selected item is shown.
  multiple = false, // New prop to allow multiple selections
  flat = false, // We use this for mobile
  // onOpenChange = () => {}, // Callback function called when dropdown open state changes.
}) => {
  const [isOpen, setOpen] = useState(false) // State to track if dropdown is open.
  const dropdownButtonRef = useRef(null)
  const dropdownRef = useRef(null)
  const [dropdownPosition, setDropdownPosition] = useState('left') // State to track the dropdown position
  useEffect(() => {
    // Handles closing the dropdown when clicking outside of it
    const handleClickOutside = event => {
      if (dropdownButtonRef.current && !dropdownButtonRef.current.contains(event.target)) {
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      const dropDown = dropdownRef.current.getBoundingClientRect()
      const dropDownButton = dropdownButtonRef.current.getBoundingClientRect()
      const windowWidth = window.innerWidth
      if (dropDownButton.left + dropDown.width > windowWidth) {
        setDropdownPosition('right')
      } else {
        setDropdownPosition('left')
      }
    }
  }, [isOpen])

  // Toggles the dropdown open state.
  const toggleDropdown = () => {
    if (type === 'boolean') {
      onChange(!value)
    } else setOpen(prevOpen => !prevOpen)
  }

  const handleOptionSelect = selectedValue => {
    if (multiple) {
      // Toggles selection for multiple select

      if (Array.isArray(value)) {
        const newValue = value.includes(selectedValue)
          ? value.filter(v => v !== selectedValue)
          : [...value, selectedValue]
        onChange(newValue)
      } else {
        onChange([selectedValue])
      }
    } else {
      onChange(selectedValue)
      if (type !== 'boolean') setOpen(false)
    }
  }
  // Renders the content of the selected option based on value.
  const renderOptionContent = optionValue => {
    var item
    if (Array.isArray(optionValue)) item = optionValue.map(opi => list.find(node => node.value === opi))
    else item = list.find(node => node.value === optionValue)

    return item ? (
      <>
        {keepPlaceholder && placeholder && <span className="filter-dropdown-control__placeholder">{placeholder}:</span>}
        {!onlyText && item.icon}
        {!onlyIcon &&
          item &&
          (Array.isArray(item)
            ? item.map((it, index) => (
                <>
                  {it?.text}
                  {index < item.length-1 && ','}
                </>
              ))
            : item?.text)}
      </>
    ) : null
  }
  const renderOptionList = () => {
    return list.map((option, idx) => (
      <li
        key={`filter-dropdown-option-${idx}`}
        className="filter-dropdown-control__option"
        onClick={e => {
          e.stopPropagation()
          handleOptionSelect(option.value)
        }}>
        <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          {option.icon && <i>{option.icon}</i>}
          {option.text}
        </span>
        {multiple && <CheckControl active={Array.isArray(value) && value.includes(option.value)} />}
        {option.tag && <span className="filter-dropdown-control__option--tag">{option.tag}</span>}
        {highlightSelected && value === option.value && <ApprovedIcon fill="var(--color-primary-pure)" />}
      </li>
    ))
  }
  const listStyle = dropdownPosition === 'right' ? 'left-auto right-0' : 'left-0 right-auto'
  // Dynamically generates class names for the dropdown based on its state and props.
  const dropdownClass = classNames('filter-dropdown-control', className, {
    'filter-dropdown-control--selected': value && value !== 'all',
    'filter-dropdown-control--list': type === 'list',
    'filter-dropdown-control--open': isOpen && !disabled,
  })

  if (placeholder === 'Status') console.log(value)

  return flat ? (
    <div>
      <div className="font-bold capitalize my-3 px-3">{title}</div>
      <ul>{renderOptionList()}</ul>
    </div>
  ) : (
    <div className={dropdownClass} onClick={toggleDropdown} ref={dropdownButtonRef}>
      <div className="filter-dropdown-control__value">
        {type === 'list' && value
          ? renderOptionContent(value)
          : placeholder && <span className="filter-dropdown-control__placeholder">{placeholder}</span>}

        {type === 'list' &&
          (arrow == 'filled' ? (
            <i className="filter-dropdown-control__arrow" />
          ) : (
            <i className="filter-dropdown-control--icon">
              <Crevron width="11px" height="7px" fillcolor="currentColor" />
            </i>
          ))}
      </div>
      {isOpen && !disabled && list && (
        <ul className={'filter-dropdown-control__list ' + listStyle} ref={dropdownRef}>
          {renderOptionList()}
        </ul>
      )}
    </div>
  )
}

// PropTypes for type-checking the props passed to the component.
FilterDropdown.propTypes = {
  placeholder: PropTypes.string,
  keepPlaceholder: PropTypes.bool,
  value: PropTypes.string,
  type: PropTypes.oneOf(['boolean', 'list']),
  list: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string,
      icon: PropTypes.element,
      tag: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  arrow: PropTypes.string,
  disabled: PropTypes.bool,
  highlightSelected: PropTypes.bool,
  onlyIcon: PropTypes.bool,
  onlyText: PropTypes.bool,
  onOpenChange: PropTypes.func,
}

export default FilterDropdown
