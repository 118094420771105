import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { i18n } from 'common/locale/i18n'
import constants from 'app/constants'
import shareService from 'social/services/share'
import BannerGrid from 'questions/components/banner-grid'
import Device from 'common/util/Device'
// import FeedSlider from 'questions/components/feed-slider'
import Helmet from 'common/components/helmet'
import OnboardingTour from 'onboarding/components/OnboardingTour'
import { feedUrl } from 'urls'
import { useABTests, useCurrentUser } from 'common/hooks'
import {
  fetchFeed,
  selectMarketRepository,
  selectFeedMarkets,
  selectFeedMarketsPagination,
  selectFeedMarketsStatus,
  selectFeedMarketsError,
  selectShouldLoadFeed,
} from 'store/markets'
import {
  fetchFeedBanners,
  selectFeedBanners,
  selectFeedBannersStatus,
  selectShouldLoadFeedBanners,
} from 'store/banners'
import { selectAbTestsStatus, selectActiveMarketType, selectPageLayout } from 'store/app'
import { useSelector, useDispatch } from 'react-redux'
import { addCampain, selectUserFromAllowedCountry } from 'store/account'
import { useLocation } from 'react-router-dom'
import { fromEntries } from 'common/util/fromEntries'
import { urlSearchParams } from 'common/util/urlSearchParams'
import { selectRootCategoryList } from 'store/categories'
import CategoryNavigation from 'explore/components/category-navigation'

import './ViewFeed.scss'
import MarketPage from 'questions/components/market-list-template'
import ErrorHandler from 'app/components/error-handler'
import futuurCover from 'landing/assets/futuur.com.png'
import QuickBet from 'questions/components/quick-bet'
import BannerGridEntry from 'questions/components/banner-grid-entry'
import FAQs from 'views/FAQs'
import HotTopic from 'questions/components/hot-topic'

const ViewFeed = () => {
  const location = useLocation()
  const [queryParams, setQueryParams] = useState(fromEntries(new urlSearchParams(location.search)))
  const dispatch = useDispatch()
  const currentUser = useCurrentUser()
  const { getABTestSegment } = useABTests()
  const [homePageTextTest, setHomePageTextTest] = useState(
    'feed_banner.' + (getABTestSegment('home-page-text') || 'Trade_the_future')
  )
  const activeMarketType = useSelector(selectActiveMarketType)
  const markets = useSelector(selectMarketRepository)
  const abTestsStatus = useSelector(selectAbTestsStatus)
  const pageLayout = useSelector(selectPageLayout)
  const feedIds = useSelector(selectFeedMarkets)
  const feedPagination = useSelector(selectFeedMarketsPagination)
  const shouldLoadFeed = useSelector(selectShouldLoadFeed(activeMarketType, location.search))
  const error = useSelector(selectFeedMarketsError)
  const feedStatus = useSelector(selectFeedMarketsStatus)
  const banners = useSelector(selectFeedBanners)
  const bannersLoading = useSelector(selectFeedBannersStatus)
  const shouldLoadBanners = useSelector(selectShouldLoadFeedBanners(activeMarketType))
  const fromAllowedCountry = useSelector(selectUserFromAllowedCountry())
  const categories = useSelector(selectRootCategoryList(activeMarketType))
  const [outcomeSelected, setOutcomeSelected] = useState(null)
  const [marketSelected, setMarketSelected] = useState(null)
  const feedBanners = banners.filter(b => !b.landing_banner && !b.hot_topic)
  // const allowRealMoney =
  //   currentUser && currentUser.id && fromAllowedCountry && currentUser.email_confirmed && !Device.isCordova

  useEffect(() => {
    if (abTestsStatus === constants.COMPLETE) {
      setTimeout(() => {
        setHomePageTextTest('feed_banner.' + getABTestSegment('home-page-text'))
      }, 500)
    }
  }, [abTestsStatus])
  useEffect(() => {
    if (shouldLoadBanners && !pageLayout.appLoading) {
      dispatch(fetchFeedBanners(activeMarketType))
    }
  }, [pageLayout.appLoading, activeMarketType])

  // useLayoutEffect(() => {
  //   setTimeout(() => {
  //     const lastScroll = scrollService.restoreScrollPosition('view-feed')
  //     if (feedIds.length && lastScroll) {
  //       window.scroll(0, lastScroll - window.innerHeight / 2)
  //       scrollService.saveScrollPosition(0, 'view-feed')
  //     }
  //   }, 0)

  //   return () => {
  //     scrollService.saveScrollPosition(window.scrollY, 'view-feed')
  //   }
  // }, [feedIds])
  useEffect(() => {
    setQueryParams(fromEntries(new urlSearchParams(location.search)))
  }, [location.search])

  useEffect(() => {
    if (pageLayout.appLoading || abTestsStatus !== constants.COMPLETE) return

    if (shouldLoadFeed) {
      dispatch(fetchFeed(activeMarketType, { limit: 30, ...queryParams }, location.search, shouldLoadFeed))
    }
  }, [pageLayout.appLoading, activeMarketType, queryParams, abTestsStatus])

  useEffect(() => {
    if (location.pathname.includes('/manifest')) addCampain('manifest')
  }, [])
  const renderHelmet = () => {
    const props = {
      title: i18n('landing.meta_tags.title'),
      description: i18n('landing.meta_tags.description', { marketCount: feedPagination.total }),
      images: futuurCover,
      url: shareService.shareableUrl(feedUrl()),
    }
    return <Helmet id="markets-helmet" {...props} />
  }
  const renderPromotionBanner = () => {
    const banner = banners.filter(b => b.landing_banner)[0]
    if (!banner) return null
    return (
      <section className="banner-grid__container">
        <div className="banner-grid container-wide landing_banner">
          <BannerGridEntry showDescription banner={{ ...banner, title: '' }} />
        </div>
      </section>
    )
  }

  const renderMakeBet = _outcomeSelected => {
    const outcome = _outcomeSelected
    return (
      <QuickBet
        marketSelected={marketSelected}
        outcome={outcome}
        setOutcomeSelected={setOutcomeSelected}
        setMarketSelected={setMarketSelected}
        shouldShow={marketSelected && marketSelected.scoring_rule_metadata}
        place="Feed"
      />
    )
  }
  const classList = classnames('view-feed', {
    'view-feed--authenticated': Boolean(currentUser),
    'view-feed--loading': constants.LOADING,
  })
  return (
    <div className={classList}>
      <ErrorHandler location={location}>
        <section className="view-feed__top">
          <div className="view-feed__banner ">
            <BannerGrid banners={Device.isMobileSize ? banners : feedBanners} text={i18n(homePageTextTest)} />
          </div>
          {Device.isMobileSize && (
            <ErrorHandler location={location}>
              <CategoryNavigation title={i18n('markets.all_markets')} size="small" subcategories={categories} />
            </ErrorHandler>
          )}

          {!Device.isMobileSize && (
            <div className="categories__banners container-wide">
              <h1>{i18n('landing.categories_banners')}</h1>{' '}
              <HotTopic banners={banners} bannersLoading={bannersLoading} />{' '}
            </div>
          )}

          {!currentUser && !pageLayout.appLoading && renderPromotionBanner()}
        </section>
      </ErrorHandler>

      <ErrorHandler location={location} component="feed">
        {/*  look here for suspeese error */}
        <MarketPage
          activeMarketType={activeMarketType}
          queryParams={queryParams}
          markets={feedIds.map(id => markets[id])}
          status={feedStatus}
          pagination={feedPagination}
          error={error}
          returnUrl={feedUrl()}
          onLoadMore={(activeMarketType, options) => {
            dispatch(fetchFeed(activeMarketType, { ...options, ...queryParams }))
          }}
          setOutcomeSelected={setOutcomeSelected}
          setMarketSelected={setMarketSelected}
          marketSelected={marketSelected}
          OutcomeSelected={outcomeSelected}
        />
        <FAQs />

        {currentUser && currentUser.settings && !currentUser.settings.seen_onboarding_app && (
          <OnboardingTour isFromAllowedCountry={fromAllowedCountry} />
        )}
        {renderMakeBet(outcomeSelected)}
      </ErrorHandler>

      {renderHelmet()}
    </div>
  )
}

export default ViewFeed
